import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  quoteContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
}));

const Quote = () => {
  const classes = useStyles();
	
	return (
		<Grid
			className={classes.quoteContainer}
			item
			md={5}
			lg={5}
		>
			<div className={classes.quote}>
				<div className={classes.quoteInner}>
					<Typography
						className={classes.quoteText}
						variant="h1"
					>
						Renseigner ses ventes dans WinBiz<br/>
						n'a jamais été aussi simple.
					</Typography>
				</div>
			</div>
		</Grid>
	);
};

export default Quote;
