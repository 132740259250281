import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { Api, ApiError } from 'Api';
import UserContext from 'UserContext';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
	TextField,
	Typography,
	Link
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
  line: {
    display: 'flex',
    flexDirection: 'row',
		minHeight: '75px',
  },
	item: {
		margin: '0.5rem',
	},
	actions: {
		marginLeft: '1rem',
	},
	subtitle: {
		marginLeft: theme.spacing(2),
	},
}));

const schema = {
  contact_firstname: {
    presence: { allowEmpty: false, message: '^Prénom obligatoire' },
    format: { pattern: "[A-Za-zÀ-ÖØ-öø-ÿ'\\s-]+", message: '^Prénom incorrect' },
    length: {
      maximum: 32,
			message: '^Longueur max: 32 caractères',
    }
  },
  contact_name: {
    presence: { allowEmpty: false, message: '^Nom obligatoire' },
    format: { pattern: "[A-Za-zÀ-ÖØ-öø-ÿ'\\s-]+", message: '^Nom incorrect' },
    length: {
      maximum: 32,
			message: '^Longueur max: 32 caractères',
    }
  },
	company_name: {
    presence: { allowEmpty: true },
    format: { pattern: ".*", message: '^Société incorrecte' },
    length: {
      maximum: 32,
			message: '^Longueur max: 32 caractères',
    }
  },
	address_1: {
    presence: { allowEmpty: false, message: '^Adresse obligatoire' },
    format: { pattern: "[0-9A-Za-zÀ-ÖØ-öø-ÿ'\\s-]+", message: '^Adresse incorrecte' },
    length: {
      maximum: 128,
			message: '^Longueur max: 128 caractères',
    }
  },
	address_2: {
    presence: { allowEmpty: true },
    format: { pattern: ".*", message: '^Adresse incorrecte' },
    length: {
      maximum: 128,
			message: '^Longueur max: 128 caractères',
    }
  },
	postcode: {
    presence: { allowEmpty: false, message: '^Code postal obligatoire' },
    format: { pattern: "[0-9]{4,}", message: '^Code postal incorrect' },
    length: {
      maximum: 8,
			message: '^Longueur max: 8 caractères',
    }
  },
	city: {
    presence: { allowEmpty: false, message: '^Ville obligatoire' },
    format: { pattern: "[A-Za-zÀ-ÖØ-öø-ÿ'\\s-]+", message: '^Ville incorrecte' },
    length: {
      maximum: 16,
			message: '^Longueur max: 16 caractères',
    }
  },
	country: {
    presence: { allowEmpty: true },
    format: { pattern: "[A-Za-zÀ-ÖØ-öø-ÿ'\\s-]*", message: '^Pays incorrect' },
    length: {
      maximum: 16,
			message: '^Longueur max: 16 caractères',
    }
  },
};

const Informations = props => {
  const { className, ...rest } = props;
	const {user, setUser} = useContext(UserContext);
	
	const [formState, setFormState] = useState({
    isValid: false,
    values: {
			contact_firstname: user.address.contactFirstname ? user.address.contactFirstname : '',
			contact_name: user.address.contactName ? user.address.contactName : '',
			company_name: user.address.companyName ? user.address.companyName : '',
			address_1: user.address.address1 ? user.address.address1 : '',
			address_2: user.address.address2 ? user.address.address2 : '',
			postcode: user.address.postcode ? user.address.postcode : '',
			city: user.address.city ? user.address.city : '',
			country: user.address.country ? user.address.country : '',
		},
    touched: {},
    errors: {},
    loading: false,
    finished: false,
  });
	
	const api = new Api({
    user: user
  });
	
  const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	
	const handleChange = event => {
		event.persist();
    
		setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
	
	const handleSave = event => {
		event.preventDefault();
		setTimeout(() => {
			api.updateCustomer(formState.values, (updated) => {
				if(updated)
				{
					api.getCustomerToken((customer) => {
						enqueueSnackbar("Modifications enregistrées", {
							variant: 'success',
							anchorOrigin: {
							 vertical: 'top',
							 horizontal: 'right',
							},
						});
						setUser(customer);
						setFormState({
							...formState,
							loading: false,
						});
				 }, 
				 [
					new ApiError({
						idError: 0,
						handle: (error, props) => {
							handleError();
						}
					}),
				 ]);
				}
				else
				{
					enqueueSnackbar("Données à jour", {
						variant: 'success',
						anchorOrigin: {
						 vertical: 'top',
						 horizontal: 'right',
						},
					});
					setFormState({
						...formState,
						loading: false,
					});
				}
			}, [
				new ApiError({
          idError: 0,
          handle: (error, props) => {
            handleError();
          },
        }),
			]);
		}, 400);
	};
	
	const handleError = () => {
		enqueueSnackbar("Impossible d'enregistrer les données, veuillez réessayer", {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
		setFormState({
			...formState,
			loading: false,
		});
	};
	
	const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
		
	useEffect(() => {
		const errors = validate(formState.values, schema);
		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		})); 
	}, [formState.values]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
				onSubmit={handleSave}
			>
        <CardHeader
          title="Informations personnelles"
				>
        </CardHeader>
				<Typography
					className={classes.subtitle}
					color="textSecondary"
					gutterBottom
				>
					Pour modifier ces informations, veuillez{' '}
				<Link 
					href="/contact"
				>
					nous contacter
				</Link>
				</Typography>
        <Divider />
        <CardContent>
          <Grid
						className={classes.container}
            container
            wrap="wrap"
          >
            <Grid
              className={classes.line}
              item
              md={10}
              sm={12}
              xs={12}
            >
              <TextField
								className={classes.item}
								fullWidth
								label="Prénom"
								name="contact_firstname"
								variant="outlined"
								value={formState.values.contact_firstname}
								onChange={handleChange}
								helperText={
									hasError('contact_firstname') ? formState.errors.contact_firstname[0] : null
								}
								error={hasError('contact_firstname')}
								disabled="true"
							/>
							
							<TextField
								className={classes.item}
								fullWidth
								label="Nom"
								name="contact_name"
								variant="outlined"
								value={formState.values.contact_name}
								onChange={handleChange}
								helperText={
									hasError('contact_name') ? formState.errors.contact_name[0] : null
								}
								error={hasError('contact_name')}
								disabled="true"
							/>
						</Grid>
						
						<Grid
              className={classes.line}
              item
              md={5}
              sm={6}
              xs={12}
            >	
							<TextField
								className={classes.item}
								fullWidth
								label="Société"
								name="company_name"
								variant="outlined"
								value={formState.values.company_name}
								onChange={handleChange}
								helperText={
									hasError('company_name') ? formState.errors.company_name[0] : null
								}
								error={hasError('company_name')}
								disabled="true"
							/>
						</Grid>
						
						<Grid
              className={classes.line}
              item
              md={10}
              sm={12}
              xs={12}
            >		
							<TextField
								className={classes.item}
								fullWidth
								label="Adresse"
								name="address_1"
								variant="outlined"
								value={formState.values.address_1}
								onChange={handleChange}
								helperText={
									hasError('address_1') ? formState.errors.address_1[0] : null
								}
								error={hasError('address_1')}
								disabled="true"
							/>
						</Grid>
						
						<Grid
              className={classes.line}
              item
              md={10}
              sm={12}
              xs={12}
            >
							
							<TextField
								className={classes.item}
								fullWidth
								label="Adresse (suite)"
								name="address_2"
								variant="outlined"
								value={formState.values.address_2}
								onChange={handleChange}
								helperText={
									hasError('address_2') ? formState.errors.address_2[0] : null
								}
								error={hasError('address_2')}
								disabled="true"
							/>
            </Grid>
						
						<Grid
              className={classes.line}
              item
              md={10}
              sm={12}
              xs={12}
            >
							
							<TextField
								className={classes.item}
								fullWidth
								label="Code postal"
								name="postcode"
								variant="outlined"
								value={formState.values.postcode}
								onChange={handleChange}
								helperText={
									hasError('postcode') ? formState.errors.postcode[0] : null
								}
								error={hasError('postcode')}
								disabled="true"
							/>
							
							<TextField
								className={classes.item}
								fullWidth
								label="Ville"
								name="city"
								variant="outlined"
								value={formState.values.city}
								onChange={handleChange}
								helperText={
									hasError('city') ? formState.errors.city[0] : null
								}
								error={hasError('city')}
								disabled="true"
							/>
            </Grid>
						
						<Grid
              className={classes.line}
              item
              md={5}
              sm={6}
              xs={12}
            >
							
							<TextField
								className={classes.item}
								fullWidth
								label="Pays"
								name="country"
								variant="outlined"
								value={formState.values.country}
								onChange={handleChange}
								helperText={
									hasError('country') ? formState.errors.country[0] : null
								}
								error={hasError('country')}
								disabled="true"
							/>
            </Grid>
          </Grid>
        </CardContent>
        {/*<Divider />
        /*<CardActions className={classes.actions}>
          <LoadingButton
            color="primary"
            variant="outlined"
						type="submit"
						disabled={!formState.isValid}
						loading={formState.loading}
						done={formState.finished}
						onClick={() => {
								setFormState(formState => ({
									...formState,
									loading: true,
							}));
						}}
          >
            Enregistrer
          </LoadingButton>
        </CardActions>*/}
      </form>
    </Card>
  );
};

Informations.propTypes = {
  className: PropTypes.string
};

export default Informations;
