import React, { useState, useEffect } from 'react';
import { withRouter} from 'react-router-dom';
import { Api, ApiError } from 'Api';

import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { LoadingButton, Quote } from 'components';
import { useSnackbar } from 'notistack';

const schema = {
  email: {
		presence: { allowEmpty: false, message: 'obligatoire' },
		email: { message: 'incorrect' },
		length: {
			maximum: 64,
			message: '^Longueur max: 64 caractères',
		}
	},
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const PasswordReset = props => {

  const api = new Api();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    loading: false,
    finished: false,
		step: 1,
  });

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handlePasswordReset = event => {
    event.preventDefault();
    setTimeout(() => {
      const errors = [
       new ApiError({
         idError: 0,
         handle: (error, props) => {
         enqueueSnackbar("Oops, on dirait qu'il y a un problème. Si le problème persiste, veuillez nous contacter.", {
           variant: 'error',
           anchorOrigin: {
             vertical: 'top',
             horizontal: 'right',
           },
         });
         setFormState(formState => ({
           ...formState,
           loading: false,
         }));
         }
       }),
			 new ApiError({
         idError: 12,
         handle: (error, props) => {
         enqueueSnackbar("Aucun compte client avec cette adresse email", {
           variant: 'error',
           anchorOrigin: {
             vertical: 'top',
             horizontal: 'right',
           },
         });
         setFormState(formState => ({
           ...formState,
           loading: false,
         }));
         }
       }),
      ];
      api.login((token) => {
        api.customerPasswordReset(formState.values.email, () => {
          setFormState(formState => ({
						...formState,
						loading: false,
						step: 2,
          }));
        }, errors);
      }, errors);
    }, 900);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
	

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		})); 
    }, [formState.values]);
		
	if(formState.step === 1)
	{
		return (
			<div className={classes.root}>
				<Grid
					className={classes.grid}
					container
				>
					<Quote />
					<Grid
						className={classes.content}
						item
						md={7}
						lg={7}
						xs={12}
					>
						<div className={classes.content}>
							<div className={classes.contentHeader}>
							</div>
							<div className={classes.contentBody}>
								<form
									className={classes.form}
									onSubmit={handlePasswordReset}
								>
									<Typography
										className={classes.title}
										variant="h2"
									>
										Mot de passe oublié ?
									</Typography>
									<TextField
										className={classes.textField}
										error={hasError('email')}
										fullWidth
										helperText={
											hasError('email') ? formState.errors.email[0] : null
										}
										label="Email"
										name="email"
										onChange={handleChange}
										type="text"
										value={formState.values.email || ''}
										variant="outlined"
									/>
									<LoadingButton
										className={classes.signInButton}
										color="primary"
										disabled={!formState.isValid}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
										loading={formState.loading}
										done={formState.finished}
										onClick={() => {
											setFormState(formState => ({
												...formState,
												loading: true,
										}));
										}}
									>
										Récupérer le mot de passe
									</LoadingButton>
									
								</form>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
	else
	{
		return (
			<div className={classes.root}>
				<Grid
					className={classes.grid}
					container
				>
					<Quote />
					<Grid
						className={classes.content}
						item
						lg={7}
						md={7}
						xs={12}
					>
						<div className={classes.form}>
							<div className={classes.content}>
								<div className={classes.contentBody}>
									<Typography
										className={classes.subtitle}
										color="textSecondary"
										gutterBottom
									>
										Nous vous avons envoyé un mail à {formState.values.email} pour vous permettre de réinitialiser votre mot de passe.
									</Typography>
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
};

PasswordReset.propTypes = {
  history: PropTypes.object
};

export default withRouter(PasswordReset);
