import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
	<div
        {...rest}
        className={clsx(classes.root, className)}
    >
	</div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
