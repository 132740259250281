import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { Sidebar, Topbar, Footer } from './components';
import UserContext from 'UserContext';
import { Api, ApiError } from 'Api';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const Main = props => {
  const { children } = props;
  const [openSidebar, setOpenSidebar] = useState(false);
  const [redirect, setRedirect] = useState();
  const { user, setUser } = useContext(UserContext);
  
  const api = new Api();

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  
  if(redirect != null)
  {
	return (
	  <Redirect
	    to={redirect}
	  />
    );
  }
	let url = children.props.location.search;
  let params = queryString.parse(url);
  if((user === null) || (params.token) || ((user !== null) && user.inactiveUser))
  {
	 let allowInactiveCustomer = 0;
	 if(params.token)
	 {
		 api.setToken(params.token);
			if(children.props.location.pathname === "/settings")
			 allowInactiveCustomer = 1;
	 }
	 api.getCustomerToken(allowInactiveCustomer, (customer) => {
		 if(allowInactiveCustomer === 1)
			 customer.inactiveUser = true;
		 else
			 customer.inactiveUser = false;
	   setUser(customer);
	 }, 
	 [
		new ApiError({
			idError: 9,
			handle: (error, props) => {
				setRedirect("sign-in?error=9");
			}
		}),
    new ApiError({
	    idError: 37,
      handle: (error, props) => {
        setRedirect("sign-in?error=37");
      }
    }),
		new ApiError({
	    idError: 88,
      handle: (error, props) => {
					setRedirect("sign-in?error=88");
      }
    }),
    new ApiError({
	    idError: 0,
      handle: (error, props) => {
        setRedirect("sign-in?error=0");
      }
	 }),
   ]);
  }
  
  return (
    <div
	  className={clsx({
	    [classes.root]: true,
	    [classes.shiftContent]: isDesktop
	  })}
    >
	  <Topbar onSidebarOpen={handleSidebarOpen} />
	  <Sidebar
	    onClose={handleSidebarClose}
	    open={shouldOpenSidebar}
	    variant={isDesktop ? 'persistent' : 'temporary'}
	  />
	  <main className={classes.content}>
	    {children}
	    <Footer />
	  </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
