import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { Api, ApiError } from 'Api';
import UserContext from 'UserContext';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
	TextField,
	Typography,
	Link,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
  line: {
    display: 'flex',
    flexDirection: 'row',
		minHeight: '70px',
  },
	item: {
		margin: '0.5rem',
	},
	actions: {
		marginLeft: '1rem',
	},
	subtitle: {
		marginLeft: theme.spacing(2),
	},
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'obligatoire' },
    email: { message: 'incorrect' },
    length: {
      maximum: 64,
			message: '^Longueur max: 64 caractères',
    }
  },
  phone: {
    presence: { allowEmpty: false, message: '^Téléphone obligatoire' },
		format: { pattern: '((((\\+)|(00))[0-9]{2}[1-9])[0-9]+)|(0[1-9][0-9]+)', message: '^Téléphone incorrect' },
    length: {
      maximum: 16,
			message: '^Longueur max: 16 caractères',
    }
  }
};

const Contact = props => {
  const { className, ...rest } = props;
	const {user, setUser} = useContext(UserContext);
	
	const [formState, setFormState] = useState({
    isValid: false,
    values: {
			email: user.email ? user.email : '',
			phone: user.address.phone ? user.address.phone : '',
		},
    touched: {},
    errors: {},
    loading: false,
    finished: false,
  });
	
	const api = new Api({
    user: user
  });

  const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	
	const handleChange = event => {
		event.persist();
    
		setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
	
	const handleSave = event => {
		event.preventDefault();
		setTimeout(() => {
			api.updateCustomer(formState.values, (updated) => {
				if(updated)
				{
					api.getCustomerToken((customer) => {
						enqueueSnackbar("Modifications enregistrées", {
							variant: 'success',
							anchorOrigin: {
							 vertical: 'top',
							 horizontal: 'right',
							},
						});
						setUser(customer);
						setFormState({
							...formState,
							loading: false,
						});
				 }, 
				 [
					new ApiError({
						idError: 0,
						handle: (error, props) => {
							handleError();
						}
					}),
				 ]);
				}
				else
				{
					enqueueSnackbar("Données à jour", {
						variant: 'success',
						anchorOrigin: {
						 vertical: 'top',
						 horizontal: 'right',
						},
					});
					setFormState({
						...formState,
						loading: false,
					});
				}
			}, [
				new ApiError({
          idError: 0,
          handle: (error, props) => {
            handleError();
          },
        }),
				new ApiError({
          idError: 22,
          handle: (error, props) => {
            enqueueSnackbar("Un client avec cette adresse email existe déjà", {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
						setFormState({
							...formState,
							loading: false,
						});
          },
        }),
			]);
		}, 400);
	};
	
	const handleError = () => {
		enqueueSnackbar("Impossible d'enregistrer les données, veuillez réessayer", {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
		setFormState({
			...formState,
			loading: false,
		});
	};
	
	const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
		
	useEffect(() => {
		const errors = validate(formState.values, schema);
		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		})); 
	}, [formState.values]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
				onSubmit={handleSave}
			>
        <CardHeader
          title="Informations de contact"
        />
				<Typography
					className={classes.subtitle}
					color="textSecondary"
					gutterBottom
				>
					Pour modifier ces informations, veuillez{' '}
				<Link 
					href="/contact"
				>
					nous contacter
				</Link>
				</Typography>
        <Divider />
        <CardContent>
          <Grid
						className={classes.container}
            container
            wrap="wrap"
          >
            <Grid
              className={classes.line}
              item
              md={10}
              sm={12}
              xs={12}
            >
              <TextField
								className={classes.item}
								fullWidth
								label="Email"
								name="email"
								variant="outlined"
								type="email"
								value={formState.values.email}
								onChange={handleChange}
								helperText={
									hasError('email') ? formState.errors.email[0] : null
								}
								error={hasError('email')}
								disabled="true"
							/>
						</Grid>
						
						<Grid
              className={classes.line}
              item
              md={10}
              sm={12}
              xs={12}
            >	
							<TextField
								className={classes.item}
								fullWidth
								label="Téléphone"
								name="phone"
								variant="outlined"
								type="tel"
								value={formState.values.phone}
								onChange={handleChange}
								helperText={
									hasError('phone') ? formState.errors.phone[0] : null
								}
								error={hasError('phone')}
								disabled="true"
							/>
						</Grid>
          </Grid>
        </CardContent>
        {/*<Divider />
        <CardActions className={classes.actions}>
          <LoadingButton
            color="primary"
            variant="outlined"
						type="submit"
						disabled={!formState.isValid}
						loading={formState.loading}
						done={formState.finished}
						onClick={() => {
								setFormState(formState => ({
									...formState,
									loading: true,
							}));
						}}
          >
            Enregistrer
          </LoadingButton>
        </CardActions>*/}
      </form>
    </Card>
  );
};

Contact.propTypes = {
  className: PropTypes.string
};

export default Contact;
