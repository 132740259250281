import React, { useState, useEffect } from 'react';
import { withRouter} from 'react-router-dom';
import { Api, ApiError } from 'Api';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
	CircularProgress,
} from '@material-ui/core';
import { MaskConfig } from './components';
import { FileUploader } from 'components';
import queryString from 'query-string';


const useStyles = makeStyles(theme => ({
  root: {
		padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
	content: {
		height: '100%',
	},
	loading: {
	 display: 'flex',
	 justifyContent: 'center',
	 alignItems: 'center',
   flexGrow: 1,
  },
}));

const MaskFromFile = props => {

  const api = new Api();

  const classes = useStyles();

	let url = props.location.search;
  let params = queryString.parse(url);
	const [formState, setFormState] = useState({
		idMask: params.idMask,
		config: undefined,
		loading: true,
		finished: false,
		employee: null,
  });

	const onFinished = () => {
		setFormState({
			...formState,
			finished: true,
		});
	};
	
	const onReset = () => {
		setFormState({
			...formState,
			finished: false,
			config: undefined,
		});
	};
	
	useEffect(() => {
		if(formState.employee === null)
		{
			const forestLogin = (token, email) => {
				api.forestLogin(token, email, () => {
					setFormState({
						...formState,
						loading: false,
					});
				},
				[
					new ApiError({
						idError: 0,
						handle: (error, props) => {
							
						},
					}),
				]);
			};
			if(api.token !== null)
			{
				api.getEmployeeToken((employee) => {
						if(employee.email !== params.employee)
							forestLogin(params.token, params.employee);
						else
						{
							setFormState({
								...formState,
								loading: false,
								employee: employee,
							});
						}
					},
					[
						new ApiError({
							idError: 0,
							handle: (error, props) => {
								
							},
						}),
						new ApiError({
							idError: 6,
							handle: (error, props) => {
								forestLogin(params.token, params.employee);
							},
						}),
						new ApiError({
							idError: 7,
							handle: (error, props) => {
								forestLogin(params.token, params.employee);
							},
						}),
						new ApiError({
							idError: 102,
							handle: (error, props) => {
								forestLogin(params.token, params.employee);
							},
						}),
					]);
			}
			else
				forestLogin(params.token, params.employee);
		}
	});
		  
  return (
    <div className={classes.root}>
			<Grid
				className={classes.content}
				container
				justify="center"
				spacing={4}
			>
				{formState.loading &&
				<div className={classes.loading}>
					<CircularProgress size={30} />
				</div>
				}
				{!formState.loading && !formState.finished &&
				<Grid
					item
					xs={8}
				>
					<FileUploader
						admin={true}
						idmask={formState.idMask}
						onAdminUpdate={(config) => {
							setFormState({
								...formState,
								config: config
							});
						}}
						onReset={onReset}
					/>
				</Grid>
				}
				{formState.config && 
				<Grid
					item
					xs={12}
				>
					<MaskConfig
						idmask = {formState.idMask}
						config={formState.config}
						onFinished={onFinished}
						onReset={onReset}
					/>
				</Grid>
				}
			</Grid>
    </div>
  );
};

MaskFromFile.propTypes = {
  history: PropTypes.object
};

export default withRouter(MaskFromFile);
