import React, { useContext, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import UserContext from 'UserContext';

import {
  LatestImports,
	Device,
} from './components';

import { FileUploader } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  loading: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  
  const { user } = useContext(UserContext);
  const [refreshLatestImports, setRefreshLatestImports] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState(false);
 
    
  const updateExports = () => {
    setRefreshLatestImports(true);
  }
  
  if(user != null)
  {
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="center"
          spacing={4}
        >
					
					<Grid
            item
            xs={4}
          >
            <Device setSelectedDevice={setSelectedDevice}/>
          </Grid>
        
          <Grid
            item
            xs={8}
          >
            <FileUploader
							iddevice={(selectedDevice ? selectedDevice.idCustomerDevice : false)}
							onUpdate={updateExports}
						/>
          </Grid>
        
          <Grid
            item
            xs={12}
          >
            <LatestImports refresh={refreshLatestImports} onRefresh={() => { setRefreshLatestImports(false); }} />
          </Grid>
       </Grid>
      </div>
    );
  }
  else
  {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
};

export default Dashboard;
