import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Typography,
  Link,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Animator } from "lottie-react";
import checkedIcon from "assets/checked_icon.json";
import UserContext from 'UserContext';
import { Api, ApiError } from 'Api';
import { saveAs } from 'file-saver';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  root: {
    height: '280px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: '100%',
		height: '250px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    background: 'none',
    borderRadius: '5px',
    flexDirection: 'column',
  },
  title: {
    color: '#263238',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase'
  },
}));

const FileUploader = props => {
  const { className, admin, idmask, iddevice, onUpdate, onAdminUpdate, onReset, ...rest } = props;
	const [idDevice, setIdDevice] = useState(iddevice);
	const [idMask, setIdMask] = useState(idmask);
  const [uploadState, setUploadState] = useState({
    error: null,
    success: false,
    loading: false,
    downloadUrl: null,
  });
  
  const { user } = useContext(UserContext);
  const api = new Api({
    user: user
  });

  const classes = useStyles();
  
  const errors = {
	  config: {
		  errorMsg: "Il semble qu'il y ait un problème dans la configuration de votre compte",
		  contact: true,
	  },
	  other: {
		  errorMsg: "Une erreur est survenue. Si le problème persiste, nous contacter.",
      contact: false,
	  }
  };
  
  const handleError = (error, idError = 0, updateExports = false, errorMsg = null, contact = null) => {
    setUploadState({
      ...uploadState,
      error: {
        idError: error.idError !== undefined ? error.idError : idError,
        errorMsg: errorMsg !== null ? errorMsg : error.errorMsg,
        contact: contact !== null ? contact : error.contact,
      },
    });
    if(updateExports && !admin)
      onUpdate();
  }
  
  const getConfigErrors = (updateExports) => {
    let ids = [9, 11, 12, 138, 139, 140, 141, 143, 148, 150, 151];
    let errors = [];
    ids.forEach((idError) => {
      errors.push(new ApiError({
        idError: idError,
        handle: (error, props) => {
          handleError(errors.config, error.state.idError, updateExports);
        },
      }));
    });
  }
  
  const uploadFile = (file) => {
    setUploadState({
      ...uploadState,
      loading: true
    });
    setTimeout(() => {
      let uploadErrors = [
        new ApiError({
          idError: 0,
          handle: (error, props) => {
            handleError(errors.other, error.state.idError, true);
          },
        }),
        new ApiError({
          idError: 146,
          handle: (error, props) => {
            handleError({
              idError: error.state.idError,
              errorMsg: error.state.errorMsg,
              contact: false,
            }, 0, true);
          },
        }),
        new ApiError({
          idError: 149,
          handle: (error, props) => {
            handleError({
              idError: error.state.idError,
              errorMsg: "Fichier incorrect : aucune ligne à importer",
              contact: false,
            }, 0, true);
          },
        }),
				new ApiError({
          idError: 158,
          handle: (error, props) => {
            handleError({
              idError: error.state.idError,
              errorMsg: "Votre abonnement est inactif ou expiré",
              contact: true,
            }, 0, true);
          },
        }),
      ];
      uploadErrors.concat(getConfigErrors(true));
			if(admin)
			{
				api.uploadCSVAdmin(idMask, file, (config) => {
					setUploadState({
						...uploadState,
						loading: false,
						success: true,
					});
					onAdminUpdate(config);
				}, uploadErrors);
			}
			else
			{
				api.uploadCSV(idDevice, file, (idWBExport) => {
					let fileName = "bizexdoc-" + idWBExport + "-" + moment(new Date()).format('YYYY-MM-DD') + ".csv";
					let getErrors = [
						new ApiError({
							idError: 0,
							handle: (error, props) => {
								handleError(errors.other, error.state.idError);
							},
						}),
					];
					getErrors.concat(getConfigErrors(false));
					
					api.getWBExportFile(idWBExport, fileName, (file, downloadUrl) => {
						setUploadState({
							...uploadState,
							loading: false,
							success: true,
							downloadUrl: downloadUrl,
						});
						setTimeout(() => {
								saveAs(file, fileName, true);
						}, 500);
					}, getErrors);
					onUpdate();
				}, uploadErrors);
			}
      
    }, 500);
    
  };
	
	useEffect(() => {
    if(idmask !== idMask)
    {
			setIdMask(idmask);
			setUploadState({
				...uploadState,
				error: null,
				success: false,
			});
    }
		if(iddevice !== idDevice)
    {
			setIdDevice(iddevice);
			setUploadState({
				...uploadState,
				error: null,
				success: false,
			});
    }
		
  }, [idmask, idMask, iddevice, idDevice, uploadState]);
 
  if(uploadState.error !== null)
  {
    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent
          justify="center"
        >
          <div
            style= {{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <ErrorIcon
              style={{ fontSize: 120 }}
              color='error'
            />
            <Typography
              className={classes.quoteText}
              variant="h3"
            >
              Oops...
            </Typography>
            <Typography
              className={classes.quoteText}
              variant="h6"
              style={{ marginTop: '10px' }}
            >
              {uploadState.error.errorMsg}
            </Typography>
            {uploadState.error.contact &&
              <Typography
                className={classes.quoteText}
                variant="h6"
                style={{ marginTop: '10px' }}
              >
                Veuillez{' '}
                <Link href="/contact">
                  nous contacter
                </Link>
              </Typography>
            }
            <Typography
              className={classes.quoteText}
              variant="caption"
              style={{
                marginTop: '20px',
                fontStyle: 'italic',
              }}
            >
              Code erreur : {uploadState.error.idError}
            </Typography>
            <Button
              color="primary"
              size="small"
              variant="text"
              style={{ marginTop: '10px' }}
              onClick={() => {
                setUploadState({
                  error: null,
                  success: false,
                });
								if(onReset)
									onReset();
              }}
							
                
            >
              <ArrowLeftIcon /> Retour
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
  else if(uploadState.loading === true)
  {
    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent
          justify="center"
        >
          <div
            className={classes.content}
          >
            <CircularProgress />
            <Typography
              className={classes.title}
              variant="h3"
              style={{
                marginTop: '20px',
              }}
            >
              Traitement du fichier...
            </Typography>
          </div>
            
        </CardContent>
      </Card>
    );
  }
  else if(uploadState.success === true)
  {
    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent
          justify="center"
          className={classes.content}
        >
          <Animator
            animationData={checkedIcon}
            autoplay={true}
            loop={false}
            style={{
              width: '150px',
              height: '150px',
            }}
          >
          </Animator>
          <Typography
            className={classes.quoteText}
            variant="h5"
            style={{
              color: '#08a88a',
            }}
          >
            Fichier traité avec succès !
          </Typography>
					{!admin &&
          <Typography
            className={classes.quoteText}
            variant="caption"
            style={{
              marginTop: '20px',
            }}
          >
            Si le téléchargement ne démarre pas,{' '}
              <Link 
                href={uploadState.downloadUrl}
                target="_blank"
              >
                cliquez ici
              </Link>
          </Typography>
					}
          <Button
            color="primary"
            size="small"
            variant="text"
            style={{ marginTop: '30px' }}
            onClick={() => {
              setUploadState({
                error: null,
                success: false,
                loading: false,
              });
							if(onReset)
								onReset();
            }}
              
          >
            <ArrowLeftIcon /> Retour
          </Button>
        </CardContent>
      </Card>
    );
  }
  else if(idMask || idDevice)
  {
    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent
          justify="center"
          style= {{
            width: "100%",
          }}
        >
          <DropzoneArea
            dropzoneText="Glissez un fichier ou cliquez pour importer"
            dropzoneClass={classes.content}
            dropzoneParagraphClass={classes.title}
            showPreviews={false}
            showPreviewsInDropzone={false}
            showAlerts={false}
            acceptedFiles={['.csv']}
            filesLimit={1}
            onChange={(loadFiles) => {
             uploadFile(loadFiles[0]); 
            }}
          />
        </CardContent>
      </Card>
    );
  }
	else
	{
		return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent
          justify="center"
        >
          <div
            className={classes.content}
          >
            <Typography
              variant="h6"
            >
              Veuillez sélectionner une machine.
						</Typography>
          </div>
            
        </CardContent>
      </Card>
    );
	}
};

FileUploader.propTypes = {
  className: PropTypes.string
};

export default FileUploader;
