import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const CGVDialog = props => {
	const { open, handleClose } = props;
	
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle id="responsive-dialog-title">{"Conditions générales de vente"}</DialogTitle>
			<DialogContent>
				<div>
					<p><strong><em>Prix</em></strong><em><br /> Les prix de ce portail d’échanges (prestation) sont indiqués en francs suisses, montants nets, TVA comprise et garantis stables.<br /> Il s’agit d’un abonnement sans engagement, selon la formule choisie il est mensuel ou annuel. il peut être rompu à tout moment sans motif et ce au terme de la dernière échéance échue. Il n’y a donc pas de possibilités, sauf accord bi latéral de remboursement de la période engagée et payée.</em><br /> <br /> <strong><em>Disponibilité et </em></strong><em><strong>Assistance</strong></em><em><br /> L’assistance technique est prévue aux heures standard de bureau&nbsp;: du lundi au vendredi de 8h00 à 12h00 et de 13h00 à 17h30. Elle est limitée au bon fonctionnement du portail d’échanges et ne peut, sauf accord déborder sur d’autres disfonctionnements (panne d’ordinateur par exemple) même si cette cause externe empêche le fonctionnement du portail. En cas d’indisponibilité, nous vous informerons et reprendrons contact dans les meilleurs délais.et ce, avec une possibilité de se situer hors de ces horaires standard avec votre accord.</em><br /> <br /> <strong><em>Modalités de paiement</em></strong><em><br /> Méthodes de paiement : PayPal., Prélèvement type SEPA, BVR ou Virement bancaire à échéance, convenu contractuellement au départ de l’engagement des deux parties.</em><em><br /> <br /> <strong>Livraison et transport</strong><br /> Le délai de mise a libre disponibilité du portail d’échange (prestation) est de 4 jours ouvrables. Ce délai dépend principalement du paramétrage nécessaire a l’application, ce délai peut être plus court.</em></p>
					<p><br /><em><strong>Mise a jour informatique du portail d’échanges (Prestation).</strong><br />Les mises à jour sont gratuites et non limitées dans le temps. Elles ne requièrent aucune action de la part de l’utilisateur qui en sera informé, elles servent à améliorer le bon fonctionnement et/ou la sécurité de l’application.</em></p>
					<p><br /> <strong><em>Retour ou échange de marchandises</em></strong><em><br /> Sans objet.&nbsp;Si le contrat est rompu le Client n’a aucune obligation de retour.<br /> <br /> <strong>Garanties légales</strong><br /> En cas de disfonctionnement du portal d’échanges, vous bénéficiez d’une totale garantie et est limitée dans le temps a la durée de votre contrat. AVDM Solutions interviendra le plus rapidement possible pour apporter un correctif et/ou trouver toutes autres solutions satisfaisantes. La gêne occasionnée pourra être compensée, après accord, par un avoir sur le montant de la l’abonnement<br /> <br /> <strong>Protection des données</strong><br /> Toutes les données à caractère personnel seront considérées comme confidentielles et en aucun cas ne pourront être transmise à un tiers sans accord préalable. Se référer au document «&nbsp;Norme RGPD AVDM Solutions&nbsp;»</em></p>
					<p><br /> <strong><em>Droit applicable et for juridique</em></strong><br /> <em>Pour la clientèle Suisse, Le droit applicable est le droit Suisse, et tout litige sera traité par le Tribunal d'arrondissement&nbsp;<u>Canton de Genève.</u></em></p>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" autoFocus>
					Fermer
				</Button>
			</DialogActions>
		</Dialog>
  );
};
export default CGVDialog;
