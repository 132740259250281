import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import ImportIcon from '@material-ui/icons/Backup';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  title: {
		color: theme.palette.white,
		fontSize: '20px',
		marginLeft: '10px',
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <ImportIcon 
		  color="inherit"
		  style={{ fontSize: 30 }}
	    />
		<Typography
		  className={classes.title}
          align="center"
          variant="h1"
        >
          Import WB
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
