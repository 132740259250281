import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, withRouter} from 'react-router-dom';
import { Api, ApiError } from 'Api';

import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import { LoadingButton, Quote } from 'components';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';

const schema = {
  email: {
		presence: { allowEmpty: false, message: 'obligatoire' },
		email: { message: 'incorrect' },
		length: {
			maximum: 64,
			message: '^Longueur max: 64 caractères',
		}
	},
  password: {
    presence: { allowEmpty: false, message: '^Ce champ est obligatoire' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {

  const { history } = props;
  const api = new Api();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    loading: false,
    finished: false,
  });
  
  let url = props.location.search;
  let params = queryString.parse(url);
  let error = params.error;
  const [errorToDisplay, setErrorToDisplay] = useState({
    idError: (error !== "undefined") ? parseInt(error) : null,
  });

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();
    setTimeout(() => {
      const errors = [
       new ApiError({
         idError: 0,
         handle: (error, props) => {
         enqueueSnackbar("Oops, on dirait qu'il y a un problème. Si le problème persiste, veuillez nous contacter.", {
           variant: 'error',
           anchorOrigin: {
             vertical: 'top',
             horizontal: 'right',
           },
         });
         setFormState(formState => ({
           ...formState,
           loading: false,
         }));
         }
       }),
       new ApiError({
         idError: 8,
         handle: (error, props) => {
         enqueueSnackbar("Email ou mot de passe incorrect", {
           variant: 'error',
           anchorOrigin: {
             vertical: 'top',
             horizontal: 'right',
           },
         });
         setFormState(formState => ({
           ...formState,
           loading: false,
         }));
         }
       }),
			 new ApiError({
         idError: 9,
         handle: (error, props) => {
         enqueueSnackbar("Compte inactif ou bloqué, veuillez nous contacter", {
           variant: 'error',
           anchorOrigin: {
             vertical: 'top',
             horizontal: 'right',
           },
         });
         setFormState(formState => ({
           ...formState,
           loading: false,
         }));
         }
       }),
      ];
      api.login((token) => {
        api.customerLogin(formState.values.email, formState.values.password, () => {
          history.push('/');
          setFormState(formState => ({
						...formState,
						loading: false,
          }));
        }, errors);
      }, errors);
    }, 900);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
	
  const displayError = useCallback((idError) => {
    if((idError !== null) && (idError >= 0))
    {
      switch(idError)
      {
        case 37:
          enqueueSnackbar("Veuillez vous connecter pour utiliser cette application", {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }});
          break;
				case 88:
				enqueueSnackbar("Veuillez vous connecter pour utiliser cette application", {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				}});
				break;
				case 9:
          enqueueSnackbar("Compte inactif ou bloqué, veuillez nous contacter", {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }});
          break;
        default:
          enqueueSnackbar("Oops, on dirait qu'il y a un problème. Veuillez vous reconnecter.", {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }});
        break;
      }
      setErrorToDisplay({
        idError: null
      });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    displayError(errorToDisplay.idError);
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		})); 
    }, [formState.values, errorToDisplay.idError, displayError]);
  
  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Quote />
        <Grid
          className={classes.content}
          item
					md={7}
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Connectez-vous
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Mot de passe"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <LoadingButton
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={formState.loading}
                  done={formState.finished}
                  onClick={() => {
										setFormState(formState => ({
											...formState,
											loading: true,
                  }));
                  }}
                >
                  Connexion
                </LoadingButton>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Mot de passe ou identifiant perdu ?{' '}
                  <Link
                    component={RouterLink}
                    to="/password-reset"
                    variant="h6"
                  >
                    Cliquez ici
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
