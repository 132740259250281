import { Component } from 'react';
import history from 'history.js';
var sha256 = require('js-sha256');
var windows1252 = require('windows-1252');
var DEV_MODE = false;

export class Api extends Component {

	constructor(props) {
		super(props);
		if(DEV_MODE)
			this.urlApi = "http://localhost/importwb/api/importwb/";
		else
			this.urlApi = "https://api.importwb.avdmsolutions.ch/importwb/";
		if(DEV_MODE)
			this.urlApiPublic = "http://localhost/importwb/api/public/";
		else
			this.urlApiPublic = "https://api.importwb.avdmsolutions.ch/public/";
		this.token = this.readCookie();
		this.user = (props !== undefined) ? props.user : null;
    
		this.defaultErrors = [
			new ApiError({
				idError: 0,
				handle: (error, props) => {
					throw new Error(error.errorMsg);
				},
			}),
			new ApiError({
				idError: 2,
				handle: (error, props) => {
					this.login((token) => {
						this.call(props);
					});
				},
			}),
			new ApiError({
				idError: 6,
				handle: (error, props) => {
					this.login((token) => {
						this.call(props);
					}, [], true);
				},
			}),
			new ApiError({
				idError: 32,
				handle: (error, props) => {
					history.push('/sign-in?error=32');
				},
			}),
		];
	}
	
	setToken(token) {
		this.token = token;  
		this.createCookie(token);
	}
	
	setUser(user) {
		this.user = user;
	}
		
	login(callback, errors = [], update = false) {
		var params = {
			action: 'login',
			user: 'customer',
			password: 'EZNFvx96cf63',
		};
		if(update && (this.token !== null))
		{
			params.update = this.token;

		}
		this.call({
			params: params,
			callback: (token) => {
				this.setToken(token);
				callback(token);
			},
			errors: errors,
		}, false);
	}
	
	customerLogin(email, passwd, callback, errors) {
		const props = {
			
			params: {
				action: 'customerLogin',
				login: email,
				passwd: sha256(passwd),
			},
			callback: (login) => {
				if(login === true)
					callback();
				else
					this.handleError(errors, 0, new Error("Une erreur est survenue durant la connection"), props);
			},
			errors: errors
		};
		this.call(props);
	}
	
	getCustomerToken(allowInactiveCustomer, callback, errors) {
		const props = {
			params: {
				action: 'getCustomerToken',
				allow_inactive_customer: allowInactiveCustomer,
			},
			callback: callback,
			errors: errors
		};
		console.log(props.params);
		this.call(props);
	}
	
	customerPasswordReset(email, callback, errors) {
		const props = {
			
			params: {
				action: 'customerPasswordReset',
				login: email,
			},
			callback: callback,
			errors: errors
		};
		this.call(props);
	}
	
	uploadCSV(idDevice, file, callback, errors) {
		const props = {
			params: {
				action: 'uploadCSV',
				id_device: idDevice,
			},
			callback: (url) => {
				this.doUpload(url, file, callback, errors);
			},
			errors: errors
		};
		this.call(props);
	}
	
	uploadCSVAdmin(idMask, file, callback, errors) {
		const props = {
			params: {
				action: 'uploadCSV',
				id_mask: idMask,
			},
			callback: (url) => {
				this.doUpload(url, file, callback, errors);
			},
			errors: errors
		};
		this.call(props);
	}
	
	doUpload(url, file, callback, errors) {
		this.readFile(file, (base64data) => {
      const props = {
				params: base64data,
				callback: callback,
				errors: errors
			};
			this.call(props, false, url);
		});
	}
	
	getWBExportFile(idWBExport, fileName, callback, errors) {
		const props = {
			params: {
				action: 'getWBExportFile',
				id_wb_export: idWBExport,
			},
			callback: (file) => {
        callback(file, this.urlApiPublic + "?action=getWBExportFile&token=" + this.token + "&id_wb_export=" + idWBExport);
      },
			errors: errors,
			fileName: fileName,
		};
		this.call(props);
	}
	
	getWBExportsByCustomer(limit, callback, errors) {
		const props = {
			params: {
				action: 'getWBExportsByCustomer',
				id_customer: this.user.idCustomer,
				light: 1,
				start: limit.start,
				length: limit.length,
			},
			callback: callback,
			errors: errors,
		};
		this.call(props);
	}
	
	getWBExportsCountByCustomer(callback, errors) {
		const props = {
			params: {
				action: 'getWBExportsCountByCustomer',
				id_customer: this.user.idCustomer,
			},
			callback: callback,
			errors: errors,
		};
		this.call(props);
	}
	
	createCustomer(fields, callback, errors) {
		let props = {
			params: {
				action: 'createCustomer',
				...fields,
			},
			callback: callback,
			errors: errors,
		};
		
		if(fields.joigned !== undefined)
		{
			this.readFile(fields.joigned, (base64data) => {
				let jsonFile = {
					name: fields.joigned.name,
					type: fields.joigned.type,
					data: window.btoa(base64data),
				}
				props.params.joigned = JSON.stringify(jsonFile);
				this.call(props);
			});
		}
		else
		{
			props.params.joigned = '';
			this.call(props);
		}
	}
	
	updateCustomer(fields, callback, errors) {
		const props = {
			params: {
				action: 'updateCustomer',
				...fields,
			},
			callback: callback,
			errors: errors,
		};
		this.call(props);
	}
	
	updateCustomerPassword(password, callback, errors) {
		const props = {
			params: {
				action: 'updateCustomerPassword',
				passwd: sha256(password),
			},
			callback: callback,
			errors: errors,
		};
		this.call(props);
	}
	
	sendContactMessage(subject, message, joigned, callback, errors) {
		let props = {
			params: {
				action: 'sendContactMessage',
				subject: subject,
				message: message,
			},
			callback: callback,
			errors: errors,
		};
		if(joigned !== undefined)
		{
			this.readFile(joigned, (base64data) => {
				let jsonFile = {
					name: joigned.name,
					type: joigned.type,
					data: window.btoa(base64data),
				}
				props.params.joigned = JSON.stringify(jsonFile);
				this.call(props);
			});
		}
		else
			this.call(props);
	}
	
	//Employee
	
	getEmployeeToken(callback, errors) {
		const props = {
			params: {
				action: 'getEmployeeToken',
			},
			callback: callback,
			errors: errors
		};
		this.call(props);
	}
	
	forestLogin(token, email, callback, errors) {
		const props = {
			params: {
				token: token,
				action: 'forestLogin',
				email: email,
			},
			callback: (token) => {
				this.setToken(token);
				callback();
			},
			errors: errors
		};
		this.call(props, false);
	}
	
	getWBDatas(callback, errors) {
		const props = {
			params: {
				action: 'getWBDatas'
			},
			callback: callback,
			errors: errors,
		};
		this.call(props);
	}
	
	updateMaskData(idMask, datas, callback, errors) {
		const props = {
			params: {
				action: 'updateMaskData',
				id_mask: idMask,
				datas: JSON.stringify(datas)
			},
			callback: callback,
			errors: errors,
		};
		this.call(props);
	}
	
	buildURLQuery(obj) {
		return Object.entries(obj)
            .map(pair => pair.map(encodeURIComponent).join('='))
            .join('&');
	}
	
	getIndexOfError(errors, idError) {
		var found = false;
		var i = 0;
		while((i < errors.length) && (!found))
		{
			if(errors[i].state.idError === idError)
				found = true;
			else
				i++;
		}
		if(found)
			return i;
		else
			return -1;
	}
	
	handleError(errors, idError, error, props) {
		var indexError = this.getIndexOfError(errors, idError);
    let apiError = new ApiError({
      idError: idError,
      errorMsg: error.message,
    });
		if(indexError >= 0)
			return errors[indexError].state.handle(apiError, props);
		else
			return errors[0].state.handle(apiError, props);
	}
	
	call(props, inclToken = true, url = null) {
		if(url === null)
			url = this.urlApi;
		const errors = this.defaultErrors.slice();
		props.errors.forEach(error => {
			var indexError = this.getIndexOfError(errors, error.state.idError);
			if(indexError >= 0)
				errors[indexError] = error;
			else
				errors.push(error);
		});
		if(inclToken && (this.token != null))
			props.params.token = this.token;
		return fetch(url, {
			method: 'POST',
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: this.buildURLQuery(props.params)
		})
		.then((response) => {
			return response.text().then((text) => {
				return {
					contentType: response.headers.get("content-type").split(";")[0],
					text: text,
				};
			});
		})
		.then((response) => {
			console.log(response.text);
			if(response.contentType === "application/json")
			{
				let json = JSON.parse(response.text);
				if(json.error === 0)
					props.callback(json.content);
				else
				{
					const error = Error(json.content);
					try {
						return this.handleError(errors, json.error, error, props);
					}
					catch(e) {}
				}
			}
			else if(response.contentType === "text/csv")
			{
				let fileName = (props.fileName !== "undefined") ? props.fileName : "file.csv";
				const buffer = Buffer.from(windows1252.encode(response.text), 'latin1');
				const data = buffer.buffer;
				const blob = new Blob([data], {
					type: "text/csv"
				});
				blob['name'] = fileName;
				
				props.callback(blob);
			}
		})
		.catch((error) => {
			this.handleError(errors, 0, error, props);
		});
	}
	
	readFile(file, callback) {
		let reader = new FileReader();
		
		let binary = '';
		if (FileReader.prototype.readAsBinaryString === undefined) {
			FileReader.prototype.readAsBinaryString = function (fileData) {
				reader.onload = function (e) {
					
					let  ia = null;
					let length = 0;
					if (e) {
						ia = new Uint8Array(e.target['result']);
						length = ia.length;
					}
					for (let i = 0; i < length; i++) {
						binary += String.fromCharCode(ia[i]);
					}
				};

			 reader.readAsArrayBuffer(fileData);
			 FileReader.prototype.readAsBinaryString = undefined;
			};
		}
				
		reader.onloadend = () => {
			let base64data;
			if (binary === '')
				base64data = reader.result;
			else 
				base64data = binary.toString();
			callback(base64data);
		}
		
		reader.onerror = (err) => {
			console.error(err);
		};

		reader.readAsBinaryString(file);
	}
	
	createCookie(value) {
		var date = new Date();
		date.setTime(date.getTime()+(365*24*60*60*1000));
		var expires = "; expires="+date.toGMTString();
		if(DEV_MODE)
			document.cookie = "importwb_token="+value+expires+"; path=/;"
		else
			document.cookie = "importwb_token="+value+expires+"; path=/;";
	}

	readCookie() {
		var nameEQ = "importwb_token=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1, c.length);
			}
			if (c.indexOf(nameEQ) === 0) 
				return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	utf8_encode(s) {
		return unescape(encodeURIComponent(s));
	}

	utf8_decode(s) {
		return decodeURIComponent(escape(s));
	}
}

export class ApiError extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idError: props.idError,
			handle: props.handle,
      errorMsg: props.errorMsg,
		};
	}
}