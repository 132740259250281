import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { Api, ApiError } from 'Api';
import UserContext from 'UserContext';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
	Grid,
  TextField
} from '@material-ui/core';
import { LoadingButton } from 'components';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
  line: {
    display: 'flex',
    flexDirection: 'row',
		minHeight: '80px',
  },
	item: {
		marginLeft: '0.5rem',
		marginRight: '0.5rem',
	},
	actions: {
		marginLeft: '1rem',
	}
}));

const schema = {
	password: {
		presence: { allowEmpty: false, message: '^Nouveau mot de passe obligatoire' },
		format: { pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z@$!%*?&\\d]{8,}", message: '^Minimum 8 caractères dont au moins une majuscule, une minuscule et un chiffre' },
		length: {
			maximum: 64,
			message: '^Longueur max: 64 caractères',
		}
	},
	confirm: {
		presence: { allowEmpty: false, message: '^Confirmation obligatoire' },
		passwordConfirm: true,
		length: {
			maximum: 64,
			message: '^Longueur max: 64 caractères',
		}
	}
};

const Password = props => {
  const { className, ...rest } = props;
	const {user} = useContext(UserContext);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
			password: '',
			confirm: ''
		},
    touched: {},
    errors: {},
    loading: false,
    finished: false,
  });
	
	const api = new Api({
    user: user
  });
	
	validate.validators.passwordConfirm = function(value, options, key, attributes) {
		if(value !== formState.values.password)
			return "^Les deux mots de passes doivent être identiques";
	};

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

  const handleChange = event => {
		event.persist();
    
		setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
	
	const handleSave = event => {
		event.preventDefault();
		setTimeout(() => {
			api.updateCustomerPassword(formState.values.password, () => {	
				enqueueSnackbar("Nouveau mot de passe enregistré", {
					variant: 'success',
					anchorOrigin: {
					 vertical: 'top',
					 horizontal: 'right',
					},
				});
				setFormState({
					...formState,
					values: {
						password: '',
						confirm: ''
					},
					touched: {},
					errors: {},
					loading: false,
				});
			}, [
				new ApiError({
          idError: 0,
          handle: (error, props) => {
            handleError();
          },
        }),
			]);
		}, 400);
	};
	
	const handleError = () => {
		enqueueSnackbar("Impossible de changer le mot de passe, veuillez réessayer", {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
		setFormState({
			...formState,
			loading: false,
		});
	};
	
	const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
		
	useEffect(() => {
		const errors = validate(formState.values, schema);
		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		})); 
	}, [formState.values]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
				onSubmit={handleSave}
				autoComplete="off"
			>
				<input 
					type="text"
					style={{display: 'none' }}
					name="username"
					value={user.email}
					readOnly={true}
				/>
        <CardHeader
          title="Changer le mot de passe"
        />
        <Divider />
        <CardContent>
					 <Grid
						className={classes.container}
            container
            wrap="wrap"
          >
            <Grid
              className={classes.line}
              item
              md={10}
              sm={12}
              xs={12}
            >
							<TextField
								className={classes.item}
								fullWidth
								label="Nouveau mot de passe"
								name="password"
								variant="outlined"
								type="password"
								value={formState.values.password}
								onChange={handleChange}
								helperText={
									hasError('password') ? formState.errors.password[0] : null
								}
								error={hasError('password')}
							/>
						</Grid>
						
						<Grid
              className={classes.line}
              item
              md={10}
              sm={12}
              xs={12}
            >
							<TextField
								className={classes.item}
								fullWidth
								label="Confirmez le mot de passe"
								name="confirm"
								variant="outlined"
								type="password"
								value={formState.values.confirm}
								onChange={handleChange}
								helperText={
									hasError('confirm') ? formState.errors.confirm[0] : null
								}
								error={hasError('confirm')}
							/>
						</Grid>
					</Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <LoadingButton
            color="primary"
            variant="outlined"
						type="submit"
						disabled={!formState.isValid}
						loading={formState.loading}
						done={formState.finished}
						onClick={() => {
							setFormState(formState => ({
								...formState,
								loading: true,
							}));
						}}
          >
            Mettre à jour
          </LoadingButton>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
