import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, CircularProgress } from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountCircle';
import UserContext from 'UserContext'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  loading: {
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1),
	fontSize: '16px',
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { user } = useContext(UserContext);

  if(user !== null)
  {
	let name = "";
	if((user.address.contactFirstname === null) && (user.address.contactName !== null))
		name += "M./Mme. " + user.address.contatName;
	else if((user.address.contactFirstname !== null) && (user.address.contactFirstname.length > 0))
	{
		name += user.address.contactFirstname.charAt(0).toUpperCase() + ".";
		if(user.address.contactName !== null)
			name += " " + user.address.contactName;
	}
    return (
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div>
	      <div className={classes.avatar}>
		    <AccountIcon
			  style={{ fontSize: 60 }}
			  color='primary'
		    />
		  </div>
	    </div>
        <Typography
          className={classes.name}
          variant="h4"
        >
          {name}
        </Typography>
        <Typography variant="body2">{user.address.companyName}</Typography>
      </div>
    );
  }
  else
  {
	return (
	  <div className={classes.loading}>
		<CircularProgress/>
	  </div>
	);
  }
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
