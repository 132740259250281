import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import UserContext from 'UserContext';
import { Api, ApiError } from 'Api';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/ErrorOutline';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import { StatusBullet } from 'components';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
		display: 'flex',
		flexGrow: 1,
  },
  inner: {
    minWidth: 1050,
		minHeight: 338,
		display: 'flex',
		flexGrow: 1,
  },
	loading: {
	 display: 'flex',
	 justifyContent: 'center',
	 alignItems: 'center',
   flexGrow: 1,
  },
	error: {
	 width: '100%',
	 display: 'flex',
   flexDirection: 'column',
	 justifyContent: 'center',
	 alignItems: 'center',
	 padding: theme.spacing(4),
   height: '100%',
  },
	statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  downloadButton: {
    padding: 0,
  },
  itemLoading: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ImportsTable = props => {
	const { limit, refresh, onRefresh } = props;
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const api = new Api({
    user: user
  });
  
  const [state, setState] = useState({
    exports: undefined,
		refresh: false,
    error: null,
    loadingItem: null,
  });
  
  const { enqueueSnackbar } = useSnackbar();
  
  const downloadExport = (wbExport) => {
    let fileName = "bizexdoc-" + wbExport.idWBExport + "-" + moment(wbExport.dateExport.date).format('YYYY-MM-DD') + ".csv";
    api.getWBExportFile(wbExport.idWBExport, fileName, (file, downloadUrl) => {
      setTimeout(() => {
        saveAs(file, fileName, true);
        setState({
          ...state,
          loadingItem: null
        });
      }, 500);
    }, 
    [
      new ApiError({
        idError: 0,
        handle: (error, props) => {
          enqueueSnackbar("Oops, on dirait qu'il y a un problème avec cet import.", {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          });
          setState({
            ...state,
            loadingItem: null
          });
        },
      }),
    ]);
    setState({
      ...state,
      error: null,
      loadingItem: wbExport.idWBExport,
    });
  };
	
	useEffect(() => {
    if(refresh && !state.refresh)
    {
      setState({
        exports: undefined,
        refresh: true,
        error: null,
        loadingItem: null,
      });
    }
    
    else if((user != null) && state.refresh)
    {
      onRefresh();
			setState({
				...state,
				refresh: false,
			});
			api.getWBExportsByCustomer(limit, (exports) => {
				setState({
					exports: exports,
					refresh: false,
					error: null,
					loadingItem: null,
				});
      }, 
			[
				new ApiError({
					idError: 0,
					handle: (error, props) => {
						setState({
							...state,
							refresh: false,
							error: "Impossible d'afficher les exports",
						});
					},
				}),
			]);
    }
  }, [limit, refresh, onRefresh, state, user, api]);
  
  const tableHead = (
    <TableHead>
      <TableRow>
        <TableCell>
          Date
        </TableCell>
        <TableCell>Machine</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
	</TableHead>
  );
  
  const tableExports = exports => (
    <Table>
	  {tableHead}
		<TableBody>
		  {state.exports.map(exportWB => (
	        <TableRow
		      hover
			  key={exportWB.idWBExport}
		    >
			  <TableCell>
			    {moment(exportWB.dateExport.date).format('DD/MM/YYYY')}
			  </TableCell>
			  <TableCell>{exportWB.device.brand.brandName + " " + exportWB.device.model.modelName}</TableCell>
			  <TableCell>
			    <div className={classes.statusContainer}>
				  <StatusBullet
				    className={classes.status}
				    color={exportWB.success === 1 ? 'success' : 'warning'}
				    size="sm"
				  />
				  {exportWB.success === 1 ? 'Succès' : 'Erreur'}
			    </div>
			  </TableCell>
			  <TableCell>
          {((state.loadingItem == null) || (state.loadingItem !== exportWB.idWBExport)) &&
            <IconButton
              className={classes.downloadButton}
              color="inherit"
              disabled={(state.loadingItem !== null) || (exportWB.success === 0)}
              onClick={() => { downloadExport(exportWB); }}
            >
              <DownloadIcon />
            </IconButton>
          }
          {((state.loadingItem != null) && (state.loadingItem === exportWB.idWBExport)) &&
          <div 
            className={classes.itemLoading}
          >
            <CircularProgress 
              size={24}
              color="inherit"
            />
          </div>
          }
			  </TableCell>
		    </TableRow>
		  ))}  
		</TableBody>
    </Table>
  );
  
  const loading = (
    <div className={classes.loading}>
      <CircularProgress size={30} />
    </div>
  );
  
  const error = (
    <div className={classes.error}>
      <ErrorIcon
        style={{
          fontSize: 80,
        }}
      />
      <Typography
        className={classes.quoteText}
        variant="h6"
        style={{ marginTop: '30px' }}
      >
        {state.error}
      </Typography>
    </div>
  );
  
   const noExports = (
    <div className={classes.error}>
      <Typography
        className={classes.quoteText}
        variant="h6"
        style={{ marginTop: '30px' }}
      >
        Aucun import à afficher.
      </Typography>
      <Typography
        className={classes.quoteText}
        variant="h6"
        style={{ 
          marginTop: '10px',
          fontWeight: 'bold',
        }}
      >
        Importez votre premier fichier ci-dessus.
      </Typography>
    </div>
  );

  return (
		<CardContent className={classes.content}>
			<div className={classes.inner}>
				{(state && state.exports) ? (state.exports.length > 0 ? tableExports(state.exports) : noExports) : (state.error !== null ? error : loading)}
			</div>
		</CardContent>
  );
};

ImportsTable.propTypes = {
  className: PropTypes.string,
};

export default ImportsTable;
