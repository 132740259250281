import React, { useState, useEffect, useRef } from 'react';
import { Button, CircularProgress } from '@material-ui/core';

const LoadingButton = (props) => {
  const { classes, loading, done, ...other } = props;
	
	const [width, setWidth] = useState();
	const ref = useRef(null);
	
	useEffect(() => {
		if(ref.current && ref.current.offsetWidth > 0)
			setWidth(ref.current.offsetWidth);
			
	}, [width]);

  if (done) {
    return (
      <Button  {...other} disabled>
      </Button>
    );
  }
  else if (loading) {
    return (
      <Button
				style={{ width: width }}
				{...other}
			>
        <CircularProgress
          color="inherit"
          size="26px"		  
		/>
      </Button>
    );
  } 
	else {
    return (
      <Button  ref={ref} {...other} />
    );
  }
}

export default LoadingButton;