import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Api, ApiError } from 'Api';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Quote, LoadingButton } from 'components';
import { CGVDialog, RGPDDialog } from './components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';

const schema = [
	{
		contact_firstname: {
			presence: { allowEmpty: false, message: '^Prénom obligatoire' },
			format: { pattern: "[A-Za-zÀ-ÖØ-öø-ÿ'\\s-]+", message: '^Prénom incorrect' },
			length: {
				maximum: 32,
				message: '^Longueur max: 32 caractères',
			}
		},
		contact_name: {
			presence: { allowEmpty: false, message: '^Nom obligatoire' },
			format: { pattern: "[A-Za-zÀ-ÖØ-öø-ÿ'\\s-]+", message: '^Nom incorrect' },
			length: {
				maximum: 32,
				message: '^Longueur max: 32 caractères',
			}
		},
		company_name: {
			presence: { allowEmpty: true },
			format: { pattern: ".*", message: '^Société incorrecte' },
			length: {
				maximum: 32,
				message: '^Longueur max: 32 caractères',
			}
		},
		address_1: {
			presence: { allowEmpty: false, message: '^Adresse obligatoire' },
			format: { pattern: "[0-9A-Za-zÀ-ÖØ-öø-ÿ'\\s-]+", message: '^Adresse incorrecte' },
			length: {
				maximum: 128,
				message: '^Longueur max: 128 caractères',
			}
		},
		address_2: {
			presence: { allowEmpty: true },
			format: { pattern: ".*", message: '^Adresse incorrecte' },
			length: {
				maximum: 128,
				message: '^Longueur max: 128 caractères',
			}
		},
		postcode: {
			presence: { allowEmpty: false, message: '^Code postal obligatoire' },
			format: { pattern: "[0-9]{4,}", message: '^Code postal incorrect' },
			length: {
				maximum: 8,
				message: '^Longueur max: 8 caractères',
			}
		},
		city: {
			presence: { allowEmpty: false, message: '^Ville obligatoire' },
			format: { pattern: "[A-Za-zÀ-ÖØ-öø-ÿ'\\s-]+", message: '^Ville incorrecte' },
			length: {
				maximum: 16,
				message: '^Longueur max: 16 caractères',
			}
		},
		country: {
			presence: { allowEmpty: true },
			format: { pattern: "[A-Za-zÀ-ÖØ-öø-ÿ'\\s-]*", message: '^Pays incorrect' },
			length: {
				maximum: 16,
				message: '^Longueur max: 16 caractères',
			}
		},
		phone: {
			presence: { allowEmpty: false, message: '^Téléphone obligatoire' },
			format: { pattern: '((((\\+)|(00))[0-9]{2}[1-9])[0-9]+)|(0[1-9][0-9]+)', message: '^Téléphone incorrect' },
			length: {
				maximum: 16,
				message: '^Longueur max: 16 caractères',
			}
		},
		
		policy: {
			presence: { allowEmpty: false, message: '^Vous devez accepter les conditions pour continuer' },
			inclusion: { within: [true], message: "^Vous devez accepter les conditions pour continuer" },
			checked: true
		}
	},
	{
		email: {
			presence: { allowEmpty: false, message: 'obligatoire' },
			email: { message: 'incorrect' },
			length: {
				maximum: 64,
				message: '^Longueur max: 64 caractères',
			}
		},
		passwd: {
			presence: { allowEmpty: false, message: '^Nouveau mot de passe obligatoire' },
			format: { pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z@$!%*?&\\d]{8,}", message: '^Minimum 8 caractères dont au moins une majuscule, une minuscule et un chiffre' },
			length: {
				maximum: 64,
				message: '^Longueur max: 64 caractères',
			}
		},
		device: {
			presence: { allowEmpty: true },
			format: { pattern: ".*", message: '^Machine incorrecte' },
			length: {
				maximum: 128,
				message: '^Longueur max: 128 caractères',
			}
		},
	}
];

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
		overflowY: 'auto',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(5),
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
	subtitle: {
		marginTop: '10px',
		marginBottom: '20px'
	},
  textField: {
    marginTop: theme.spacing(2)
  },
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
  line: {
    display: 'flex',
    flexDirection: 'row',
		minHeight: '80px',
  },
	item: {
		margin: '0.5rem',
	},
  policy: {
    marginTop: theme.spacing(1),
		marginLeft: '0.5rem',
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
	uploadFile: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '20px',
	},
	input: {
		display: 'none',
	},
	inputLabel: {
		whiteSpace: 'nowrap',
	},
	fileName: {
		marginLeft: theme.spacing(1),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	fileDelete: {
		padding: theme.spacing(0.5),
		marginLeft: theme.spacing(0.2),
		color: theme.palette.icon,
		verticalAlign: 'middle',
	},
}));

const SignUp = props => {

  const classes = useStyles();

  const [formState, setFormState] = useState({
		step: 0,
    isValid: false,
    values: {
			contact_firstname: '',
			contact_name: '',
			company_name: '',
			address_1: '',
			address_2: '',
			postcode: '',
			city: '',
			country: '',
			phone: '',
			email: '',
			passwd: '',
			joigned: undefined,
			device: '',
		},
    touched: {},
    errors: {},
		preventUnload: false,
		loading: false,
  });
	
	const [cgvOpen, setCgvOpen] = useState(false);
	const [rgpdOpen, setRgpdOpen] = useState(false);
	
	const api = new Api();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const errors = validate(formState.values, schema[formState.step]);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
		
		if(formState.preventUnload)
		{
			window.addEventListener("beforeunload", handleBeforeUnload);
			return () => window.removeEventListener("beforeunload", handleBeforeUnload);
		}
		
  }, [formState.values, formState.step, formState.preventUnload, cgvOpen]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      },
			preventUnload: true,
    }));
  };

  const handleBack = () => {
		const errors = validate(formState.values, schema[formState.step - 1]);
    setFormState({
			...formState,
			step: formState.step - 1,
			isValid: errors ? false : true,
      errors: errors || {}
		});
  };
	
	const handleBeforeUnload = (event) => {
		event.preventDefault();
	};

  const handleSignUp = event => {
    event.preventDefault();
		if(formState.step < 2)
		{
			const errors = validate(formState.values, schema[formState.step + 1]);
			setFormState({
				...formState,
				step: formState.step + 1,
				isValid: errors ? false : true,
				errors: errors || {}
			});
		}
		else
		{
			setFormState({
				...formState,
				loading: true
			});
			const errors = [
			 new ApiError({
				 idError: 0,
				 handle: (error, props) => {
				 enqueueSnackbar("Oops, on dirait qu'il y a un problème. Si le problème persiste, veuillez nous contacter.", {
					 variant: 'error',
					 anchorOrigin: {
						 vertical: 'top',
						 horizontal: 'right',
					 },
				 });
				 setFormState(formState => ({
					 ...formState,
					 loading: false,
				 }));
				 }
			 }),
			 new ApiError({
				 idError: 22,
				 handle: (error, props) => {
				 enqueueSnackbar("Un client existe déjà avec cette adresse email", {
					 variant: 'error',
					 anchorOrigin: {
						 vertical: 'top',
						 horizontal: 'right',
					 },
				 });
				 setFormState(formState => ({
					 ...formState,
					 loading: false,
				 }));
				 }
			 }),
			  new ApiError({
				 idError: 153,
				 handle: (error, props) => {
				 enqueueSnackbar("Format de fichier joint incorrect", {
					 variant: 'error',
					 anchorOrigin: {
						 vertical: 'top',
						 horizontal: 'right',
					 },
				 });
				 setFormState(formState => ({
					 ...formState,
					 loading: false,
				 }));
				 }
			 })
			];
			api.login((token) => {
				api.createCustomer(formState.values, () => {
					setFormState({
						...formState,
						step: formState.step + 1,
						preventUnload: false,
					});
				}, errors);
			}, errors);
		}
  };
	
	const handleFile = (event) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				joigned: event.target.files[0],
			},
		});
	};
	
	const deleteFile = (event) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				joigned: undefined,
			},
		});
	};

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  if(formState.step === 0)
	{
		return (
			<div className={classes.root}>
				<Grid
					className={classes.grid}
					container
				>
					<Quote />
					<Grid
						className={classes.content}
						item
						lg={7}
						md={7}
						xs={12}
					>
						<div className={classes.content}>
							<div className={classes.contentBody}>
								<form
									className={classes.form}
									onSubmit={handleSignUp}
								>
									<Typography
										className={classes.title}
										variant="h2"
									>
										Bienvenue
									</Typography>
									<Typography
										className={classes.subtitle}
										color="textSecondary"
										gutterBottom
									>
										Renseignez vos informations et nous vous recontactons pour finaliser la création de votre compte.
									</Typography>
									<Grid
										className={classes.container}
										container
										wrap="wrap"
									>
										<Grid
											className={classes.line}
											item
											md={10}
											sm={12}
											xs={12}
										>
											<TextField
												className={classes.item}
												fullWidth
												label="Prénom"
												name="contact_firstname"
												variant="outlined"
												value={formState.values.contact_firstname}
												onChange={handleChange}
												helperText={
													hasError('contact_firstname') ? formState.errors.contact_firstname[0] : null
												}
												error={hasError('contact_firstname')}
												required
											/>
											
											<TextField
												className={classes.item}
												fullWidth
												label="Nom"
												name="contact_name"
												variant="outlined"
												value={formState.values.contact_name}
												onChange={handleChange}
												helperText={
													hasError('contact_name') ? formState.errors.contact_name[0] : null
												}
												error={hasError('contact_name')}
												required
											/>
										</Grid>
										
										<Grid
											className={classes.line}
											item
											md={5}
											sm={6}
											xs={12}
										>	
											<TextField
												className={classes.item}
												fullWidth
												label="Société"
												name="company_name"
												variant="outlined"
												value={formState.values.company_name}
												onChange={handleChange}
												helperText={
													hasError('company_name') ? formState.errors.company_name[0] : null
												}
												error={hasError('company_name')}
											/>
										</Grid>
										
										<Grid
											className={classes.line}
											item
											md={10}
											sm={12}
											xs={12}
										>		
											<TextField
												className={classes.item}
												fullWidth
												label="Adresse"
												name="address_1"
												variant="outlined"
												value={formState.values.address_1}
												onChange={handleChange}
												helperText={
													hasError('address_1') ? formState.errors.address_1[0] : null
												}
												error={hasError('address_1')}
												required
											/>
										</Grid>
										
										<Grid
											className={classes.line}
											item
											md={10}
											sm={12}
											xs={12}
										>
											
											<TextField
												className={classes.item}
												fullWidth
												label="Adresse (suite)"
												name="address_2"
												variant="outlined"
												value={formState.values.address_2}
												onChange={handleChange}
												helperText={
													hasError('address_2') ? formState.errors.address_2[0] : null
												}
												error={hasError('address_2')}
											/>
										</Grid>
										
										<Grid
											className={classes.line}
											item
											md={10}
											sm={12}
											xs={12}
										>
											
											<TextField
												className={classes.item}
												fullWidth
												label="Code postal"
												name="postcode"
												variant="outlined"
												value={formState.values.postcode}
												onChange={handleChange}
												helperText={
													hasError('postcode') ? formState.errors.postcode[0] : null
												}
												error={hasError('postcode')}
												required
											/>
											
											<TextField
												className={classes.item}
												fullWidth
												label="Ville"
												name="city"
												variant="outlined"
												value={formState.values.city}
												onChange={handleChange}
												helperText={
													hasError('city') ? formState.errors.city[0] : null
												}
												error={hasError('city')}
												required
											/>
										</Grid>
										
										<Grid
											className={classes.line}
											item
											md={5}
											sm={6}
											xs={12}
										>
											
											<TextField
												className={classes.item}
												fullWidth
												label="Pays"
												name="country"
												variant="outlined"
												value={formState.values.country}
												onChange={handleChange}
												helperText={
													hasError('country') ? formState.errors.country[0] : null
												}
												error={hasError('country')}
											/>
										</Grid>
										
										<Grid
											className={classes.line}
											item
											md={5}
											sm={6}
											xs={12}
										>
											
											<TextField
												className={classes.item}
												fullWidth
												label="Numéro de téléphone"
												name="phone"
												variant="outlined"
												value={formState.values.phone}
												onChange={handleChange}
												helperText={
													hasError('phone') ? formState.errors.phone[0] : null
												}
												error={hasError('phone')}
												required
											/>
										</Grid>
									</Grid>
									<div className={classes.policy}>
										<Checkbox
											checked={formState.values.policy || false}
											className={classes.policyCheckbox}
											color="primary"
											name="policy"
											onChange={handleChange}
										/>
										<Typography
											className={classes.policyText}
											color="textSecondary"
											variant="body1"
										>
											J{'\''}ai lu et j{'\''}accepte les{' '}
											<Link
												color="primary"
												component={RouterLink}
												to="#"
												onClick={() => { setCgvOpen(true); }}
												underline="always"
												variant="h6"
											>
												Conditions générales
											</Link>
											{' '}* ainsi que la{' '}
											<Link
												color="primary"
												component={RouterLink}
												to="#"
												onClick={() => { setRgpdOpen(true); }}
												underline="always"
												variant="h6"
											>
												politique d'utilisation des données
											</Link>
											{' '}*
										</Typography>
									</div>
									{hasError('policy') && (
										<FormHelperText error>
											{formState.errors.policy[0]}
										</FormHelperText>
									)}
									<Button
										className={classes.signUpButton}
										color="primary"
										disabled={!formState.isValid}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										Suivant
									</Button>
									<Typography
										color="textSecondary"
										variant="body1"
									>
										Déjà client ?{' '}
										<Link
											component={RouterLink}
											to="/sign-in"
											variant="h6"
										>
											Connectez-vous
										</Link>
									</Typography>
								</form>
							</div>
						</div>
					</Grid>
				</Grid>
				<CGVDialog
					open={cgvOpen}
					handleClose={() => {
						setCgvOpen(false);
					}}
				/>
				<RGPDDialog
					open={rgpdOpen}
					handleClose={() => {
						setRgpdOpen(false);
					}}
				/>
			</div>
		);
	}
	else if(formState.step === 1)
	{
		return (
			<div className={classes.root}>
				<Grid
					className={classes.grid}
					container
				>
					<Quote />
					<Grid
						className={classes.content}
						item
						lg={7}
						md={7}
						xs={12}
					>
						<div className={classes.content}>
							<div className={classes.contentHeader}>
								<IconButton onClick={handleBack}>
									<ArrowBackIcon />
								</IconButton>
							</div>
							<div className={classes.contentBody}>
								<form
									className={classes.form}
									onSubmit={handleSignUp}
								>
									<Typography
										className={classes.title}
										variant="h2"
									>
										Presque terminé !
									</Typography>
									<Typography
										className={classes.subtitle}
										color="textSecondary"
										gutterBottom
									>
										Choisissez vos informations de connexion.
									</Typography>
									<Grid
										className={classes.container}
										container
										wrap="wrap"
									>
										<Grid
											className={classes.line}
											item
											md={10}
											sm={12}
											xs={12}
										>
											<TextField
												className={classes.item}
												fullWidth
												label="Email"
												name="email"
												variant="outlined"
												type="email"
												value={formState.values.email}
												onChange={handleChange}
												helperText={
													hasError('email') ? formState.errors.email[0] : null
												}
												error={hasError('email')}
												required
											/>
										</Grid>
										
										<Grid
											className={classes.line}
											item
											md={10}
											sm={12}
											xs={12}
										>
											<TextField
												className={classes.item}
												fullWidth
												label="Mot de passe"
												name="passwd"
												variant="outlined"
												type="password"
												style={{ marginTop: '1rem' }}
												value={formState.values.passwd}
												onChange={handleChange}
												helperText={
													hasError('passwd') ? formState.errors.passwd[0] : null
												}
												error={hasError('passwd')}
												required
											/>
										</Grid>
									</Grid>
									<Button
										className={classes.signUpButton}
										color="primary"
										disabled={!formState.isValid}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										Suivant
									</Button>
								</form>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
	else if(formState.step === 2)
	{
		return (
			<div className={classes.root}>
				<Grid
					className={classes.grid}
					container
				>
					<Quote />
					<Grid
						className={classes.content}
						item
						lg={7}
						md={7}
						xs={12}
					>
						<div className={classes.content}>
							<div className={classes.contentHeader}>
								<IconButton onClick={handleBack}>
									<ArrowBackIcon />
								</IconButton>
							</div>
							<div className={classes.contentBody}>
								<form
									className={classes.form}
									onSubmit={handleSignUp}
								>
									<Typography
										className={classes.title}
										variant="h2"
									>
										Avant de vous quitter
									</Typography>
									<Typography
										className={classes.subtitle}
										color="textSecondary"
										gutterBottom
									>
										Disposez-vous d{'\''}un fichier que vous souhaitez importer dans WinBiz ?
									</Typography>
									<Typography
										className={classes.subtitle}
										color="textSecondary"
										gutterBottom
									>
										Ce n'est pas obligatoire, mais cela nous aidera à configurer votre compte.
									</Typography>
									<Grid
										className={classes.container}
										container
										wrap="wrap"
									>
										<Grid
											className={classes.line}
											item
											md={10}
											sm={12}
											xs={12}
										>
											<div
												className={classes.uploadFile}
											>
												<input
													accept=".csv,.txt,.xls,.xlsx"
													className={classes.input}
													id="upload-file"
													multiple
													type="file"
													onChange={handleFile}
												/>
												<label 
													htmlFor="upload-file"
													className={classes.inputLabel}
												>
													<Button 
														variant="outlined"
														color="primary"
														component="span"
													>
														Joindre un fichier
													</Button>
												</label>
												{ formState.values.joigned !== undefined &&
														<Typography
															variant="caption"
															className={classes.fileName}
														>
															{ formState.values.joigned.name }
														</Typography>
												}
												{ formState.values.joigned !== undefined &&
														<IconButton
															className={classes.fileDelete}
															onClick={deleteFile}
														>
															<DeleteIcon />
														</IconButton>
												}
											</div>
										</Grid>
									</Grid>
									<Typography
										className={classes.subtitle}
										color="textSecondary"
										gutterBottom
									>
										De quel type de machine disposez-vous ?
									</Typography>
									<Grid
										className={classes.container}
										container
										wrap="wrap"
									>
										<Grid
											className={classes.line}
											item
											md={10}
											sm={12}
											xs={12}
										>
											<TextField
												className={classes.item}
												fullWidth
												label="Marque modèle"
												name="device"
												variant="outlined"
												value={formState.values.device}
												onChange={handleChange}
												helperText={
													hasError('device') ? formState.errors.device[0] : null
												}
												error={hasError('device')}
											/>
										</Grid>
									</Grid>
									<LoadingButton
										className={classes.signUpButton}
										color="primary"
										disabled={!formState.isValid}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
										loading={formState.loading}
									>
										Terminer
									</LoadingButton>
								</form>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
	else if(formState.step === 3)
	{
		return (
			<div className={classes.root}>
				<Grid
					className={classes.grid}
					container
				>
					<Quote />
					<Grid
						className={classes.content}
						item
						lg={7}
						md={7}
						xs={12}
					>
						<div className={classes.content}>
							<div className={classes.contentBody}>
								<form
									className={classes.form}
									onSubmit={handleSignUp}
								>
									<Typography
										className={classes.title}
										variant="h2"
									>
										Merci, {formState.values.contact_firstname}
									</Typography>
									<Typography
										className={classes.subtitle}
										color="textSecondary"
										gutterBottom
									>
										Nous revenons très vite vers vous pour finaliser la création de votre compte.
									</Typography>
								</form>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
