import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import UserContext from 'UserContext';
import clsx from 'clsx';
import validate from 'validate.js';
import { Api, ApiError } from 'Api';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
	TextField,
	CircularProgress,
	Button,
	IconButton,
	Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { LoadingButton } from 'components';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
  root: {
		maxWidth: '1000px',
		marginTop: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto'
  },
	item: {
		marginBottom: theme.spacing(2),
	},
	actions: {
		marginLeft: theme.spacing(1),
	},
	uploadFile: {
		display: 'flex',
		alignItems: 'center',
	},
	input: {
		display: 'none',
	},
	inputLabel: {
		whiteSpace: 'nowrap',
	},
	fileName: {
		marginLeft: theme.spacing(1),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	fileDelete: {
		padding: theme.spacing(0.5),
		marginLeft: theme.spacing(0.2),
		color: theme.palette.icon,
		verticalAlign: 'middle',
	},
	loading: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
  },
}));

const schema = {
  subject: {
    presence: { allowEmpty: false, message: '^Objet obligatoire' },
    format: { pattern: ".*", message: '^Objet incorrect' },
    length: {
      maximum: 128,
			message: '^Longueur max: 128 caractères',
    }
  },
  message: {
    presence: { allowEmpty: false, message: '^Message obligatoire' },
  },
};

const Contact = props => {
  const { className } = props;
	
	const {user} = useContext(UserContext);
	
	let url = props.location.search;
  let params = queryString.parse(url);
	let template = params.template;
	var subjectToDisplay = '';
	if(template !== undefined)
	{
		if(template === "addDevice")
			subjectToDisplay = "J'aimerais ajouter une nouvelle machine";
	}
	
	const [formState, setFormState] = useState({
    isValid: false,
    values: {
			subject: subjectToDisplay,
			message: '',
		},
    touched: {},
    errors: {},
    loading: false,
    finished: false,
  });
	
	const api = new Api({
    user: user
  });
	
  const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	
	const handleChange = event => {
		event.persist();
    
		setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
	
	const handleSave = event => {
		event.preventDefault();
		setTimeout(() => {
			let message = formState.values.message.replace("\n", "<br/>");
			api.sendContactMessage(formState.values.subject, message, formState.values.file, (sent) => {
				if(sent)
				{
					enqueueSnackbar("Message envoyé, merci.", {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					setFormState({
						isValid: false,
						values: {
							subject: '',
							message: '',
						},
						touched: {},
						errors: {},
						loading: false,
						finished: false,
					});
				}
				else
				{
					handleError();
				}
			}, [
				new ApiError({
          idError: 0,
          handle: (error, props) => {
            handleError();
          },
        }),
				new ApiError({
          idError: 153,
          handle: (error, props) => {
            handleError("Format de fichier non pris en charge");
          },
        }),
			]);
		}, 400);
	};
	
	const handleFile = (event) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				file: event.target.files[0],
			},
		});
	};
	
	const deleteFile = (event) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				file: undefined,
			},
		});
	};
	
	const handleError = (msg = null) => {
		enqueueSnackbar(msg !== null ? msg : "Impossible d'envoyer le message, veuillez réessayer", {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
		setFormState({
			...formState,
			loading: false,
		});
	};
	
	const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
		
	useEffect(() => {
		const errors = validate(formState.values, schema);
		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		})); 
	}, [formState.values]);
	
	if(user !== null)
	{
		return (
			<div className={classes.root}>
				<Card
					className={clsx(classes.root, className)}
				>
					<form
						onSubmit={handleSave}
					>
						<CardHeader
							title="Contactez-nous"
						/>
						<Divider />
						<CardContent>
							<TextField
								className={classes.item}
								fullWidth
								label="Objet"
								name="subject"
								variant="outlined"
								value={formState.values.subject}
								onChange={handleChange}
								helperText={
									hasError('subject') ? formState.errors.subject[0] : null
								}
								error={hasError('subject')}
							/>
							<TextField
								className={classes.item}
								fullWidth
								multiline
								rows={10}
								label="Message"
								name="message"
								variant="outlined"
								value={formState.values.message}
								onChange={handleChange}
								helperText={
									hasError('message') ? formState.errors.message[0] : null
								}
								error={hasError('message')}
							/>
							<div
								className={classes.uploadFile}
							>
								<input
									accept=".png,.jpg,.jpeg,.bitmap,.gif,.csv,.txt,.xls,.xlsx,.pdf"
									className={classes.input}
									id="upload-file"
									multiple
									type="file"
									onChange={handleFile}
								/>
								<label 
									htmlFor="upload-file"
									className={classes.inputLabel}
								>
									<Button variant="contained" color="primary" component="span">
										Joindre un fichier
									</Button>
								</label>
								{ formState.values.file !== undefined &&
										<Typography
											variant="caption"
											className={classes.fileName}
										>
											{ formState.values.file.name }
										</Typography>
								}
								{ formState.values.file !== undefined &&
										<IconButton
											className={classes.fileDelete}
											onClick={deleteFile}
										>
											<DeleteIcon />
										</IconButton>
								}
							</div>
						</CardContent>
						<Divider />
						<CardActions className={classes.actions}>
							<LoadingButton
								color="primary"
								variant="outlined"
								type="submit"
								disabled={!formState.isValid}
								loading={formState.loading}
								done={formState.finished}
								onClick={() => {
										setFormState(formState => ({
											...formState,
											loading: true,
									}));
								}}
							>
								Envoyer
							</LoadingButton>
						</CardActions>
					</form>
				</Card>
			</div>
		);
	}
	else
	{
		return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
	}
};

export default Contact;
