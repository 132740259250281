import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  Divider,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { ImportsTable } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    height: 440,
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    justifyContent: 'flex-end'
  },
}));

const LatestImports = props => {
  const { className, refresh, onRefresh, ...rest } = props;

  const classes = useStyles();

  const [refreshLatestImports, setRefreshLatestImports] = useState(false);
  	
	useEffect(() => {
    if(props.refresh && !refreshLatestImports)
    {
      setRefreshLatestImports(true);
    }
	}, [props.refresh, refreshLatestImports, setRefreshLatestImports]);
	
	const limit = {
		start: 0,
		length: 5
	};
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Derniers imports"
      />
      <Divider />
      <ImportsTable limit={limit} refresh={refreshLatestImports} onRefresh={
					() => {
						onRefresh();
						setRefreshLatestImports(false);
					}
				}
			/>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
					href="/imports"
        >
          Tous les imports <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestImports.propTypes = {
  className: PropTypes.string
};

export default LatestImports;
