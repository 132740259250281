import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, colors } from '@material-ui/core';
import SupportIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.grey[50]
  },
  iconContainer: {
	width: '100%',
	height: '60px',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    paddingTop: theme.spacing(2),
    color: theme.palette.icon,
    width: 40,
    height: 40,
  },
  content: {
    padding: theme.spacing(1, 2)
  },
  actions: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center',
		textAlign: 'center',
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const Support = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.iconContainer}>
		<div className={classes.icon}>
			<SupportIcon
			  style={{ fontSize: 40 }}
			/>
		</div>
	  </div>
      <div className={classes.content}>
        <Typography
          align="center"
          gutterBottom
          variant="h6"
        >
          Besoin d'aide ?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Vous rencontrez une difficulté, avez besoin de changer votre configuration ?
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          color="primary"
          component={CustomRouterLink}
					to="/contact"
          variant="contained"
					style={{fontSize: 13}}
        >
          Contactez-nous
        </Button>
      </div>
    </div>
  );
};

Support.propTypes = {
  className: PropTypes.string
};

export default Support;
