import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import UserContext from 'UserContext';
import { Api, ApiError } from 'Api';

import {
  Card,
  CardActions,
	TablePagination,
} from '@material-ui/core';

import { ImportsTable } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
		display: 'flex',
		flexDirection: 'column',
    margin: theme.spacing(3),
		minHeight: 440,
  },
  content: {
		padding: 0,
		flexGrow: 1,
  },
	actions: {
    justifyContent: 'flex-end'
  },
}));

const ImportList = props => {
  const { className, staticContext, ...rest } = props;
  const classes = useStyles();
	
	const { user } = useContext(UserContext);
  const api = new Api({
    user: user
  });
	
	const [state, setState] = useState({
		exportsCount: undefined,
		refreshLatestImports: false,
		rowsPerPage: 10,
		page: 0,
	});

	const handlePageChange = (event, page) => {
    setState({
			...state,
			page: page,
			refreshLatestImports: true,
		});
  };

  const handleRowsPerPageChange = event => {
    setState({
			...state,
			rowsPerPage: event.target.value,
			refreshLatestImports: true,
		});
  };
	
	const limit = {
		start: state.page * state.rowsPerPage,
		length: state.rowsPerPage
	};
	
	useEffect(() => {
		if(user != null) {
			if(!state.refreshLatestImports && (state.exportsCount === undefined))
			{
				api.getWBExportsCountByCustomer((count) => {
					setState({
						...state,
						exportsCount: parseInt(count),
						refreshLatestImports: true,
					})
				}, 
				[
					new ApiError({
						idError: 0,
						handle: (error, props) => {
							setState({
								...state,
								exportsCount: 0
							});
						},
					}),
				]);
			}
		}
	});

  return (
    <div className={classes.root}>
      <div className={classes.content}>
				<Card
					{...rest}
					className={clsx(classes.root, className)}
				>
					<ImportsTable limit={limit} refresh={state.refreshLatestImports} onRefresh={() => { setState({...state, refreshLatestImports: false}); }}/>
					<CardActions className={classes.actions}>
						{state.exportsCount !== undefined && 
						<TablePagination
							component="div"
							count={state.exportsCount}
							onChangePage={handlePageChange}
							onChangeRowsPerPage={handleRowsPerPageChange}
							page={state.page}
							rowsPerPage={state.rowsPerPage}
							rowsPerPageOptions={[5, 10, 25]}
						/>
						}
					</CardActions>
				</Card>
        
      </div>
    </div>
  );
};

export default ImportList;
