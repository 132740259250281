import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import UserContext from 'UserContext';
import { Api } from 'Api';

import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  CircularProgress,
  Typography,
	FormControl,
	Select,
	MenuItem,
	Link,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: 0,
    height: '100%',
  },
  inner: {
    display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
    height: '100%',
  },
  loading: {
	 display: 'flex',
	 justifyContent: 'center',
	 alignItems: 'center',
	 padding: theme.spacing(4),
   height: '100%',
  },
  error: {
	 display: 'flex',
   flexDirection: 'column',
	 justifyContent: 'center',
	 alignItems: 'center',
	 padding: theme.spacing(4),
   height: '100%',
  },
	formControl: {
		minWidth: '80%',
	},
	actions: {
		display: 'flex',
		justifyContent: 'center',
	}
}));

const Device = props => {
  const { className, setSelectedDevice, ...rest } = props;

  const classes = useStyles();
  
  const { user } = useContext(UserContext);
  const api = new Api({
    user: user
  });

  const [state, setState] = useState({
    devices: undefined,
		selectedDevice: undefined,
    error: null,
  });
		
	const handleDeviceChange = (idDevice) => {
		let device = undefined;
		let i = 0;
		while((i < state.devices.length) && (device === undefined))
		{
			if(state.devices[i].idCustomerDevice === idDevice)
				device = state.devices[i];
			i++;
		}
		setState({
			...state,
			selectedDevice: device,
		});
	};
  
  useEffect(() => {
    if((user != null) && (state.devices === undefined))
    {
			setState({
				devices: user.devices,
				selectedDevice: (user.devices && (user.devices.length > 0)) ? user.devices[0] : undefined,
				error: null,
			});
    }
		else if((state.selectedDevice !== undefined))
		{
			setSelectedDevice(state.selectedDevice);
		}
  }, [state, user, setSelectedDevice, api]);
	
	const devicesList = (devices) => (
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				value={state.selectedDevice.idCustomerDevice}
				onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
					handleDeviceChange(event.target.value);
				}}
			>
				{devices.map(device => (
					<MenuItem
						key={device.idCustomerDevice}
						value={device.idCustomerDevice}
					>
						{device.brand.brandName + " " + device.model.modelName}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
  
  const loading = (
    <div className={classes.loading}>
      <CircularProgress size={30} />
    </div>
  );
	
  const error = (
    <div className={classes.error}>
      <ErrorIcon
        style={{
          fontSize: 80,
        }}
      />
      <Typography
        className={classes.quoteText}
        variant="h6"
        style={{ marginTop: '30px' }}
      >
        {state.error}
      </Typography>
    </div>
  );
  
   const noDevices = (
    <div className={classes.error}>
      <Typography
        className={classes.quoteText}
        variant="h6"
        style={{ marginTop: '30px' }}
      >
        Aucune machine associée à votre compte.
      </Typography>
      <Typography
				variant="h6"
				style={{ marginTop: '10px' }}
			>
				Veuillez{' '}
				<Link 
					href="/contact"
				>
					nous contacter
				</Link>
			</Typography>
    </div>
  );
 
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Sélectionnez la machine"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.inner}>
        {(state && state.devices) ? (state.devices.length > 0 ? devicesList(state.devices) : noDevices) : (state.error !== null ? error : loading)}
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
					href="/contact?template=addDevice"
					disabled={(state.devices === undefined) || (state.devices.length === 0)}
        >
          <AddIcon style={{marginRight: 10}} /> Ajouter une machine
        </Button>
      </CardActions>
    </Card>
  );
};

Device.propTypes = {
  className: PropTypes.string
};

export default Device;
