import React from 'react';
import { useContext} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import UserContext from 'UserContext';

import { CircularProgress } from '@material-ui/core';
import { Informations, Contact, Password } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
	loading: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
  },
}));

const Settings = () => {
  const classes = useStyles();
	
	const {user} = useContext(UserContext);
	
	if(user !== null)
	{
		return (
			<div className={classes.root}>
				<Grid
					container
					spacing={4}
				>
					<Grid
						item
						md={7}
						xs={12}
					>
						<Informations />
					</Grid>
					
					<Grid
						item
						md={5}
						xs={12}
					>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								md={12}
								xs={12}
							>
								<Contact user={user}/>
							</Grid>
							
							<Grid
								item
								md={12}
								xs={12}
							>
								<Password user={user}/>
							</Grid>
						</Grid>
					</Grid>
					
				</Grid>
			</div>
		);
	}
	else
	{
		return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
	}
};

export default Settings;
