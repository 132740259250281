import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import UserContext from 'UserContext';
import { Api, ApiError } from 'Api';
import {
	Card,
  CardContent,
	CardHeader,
	CardActions,
	Button,
	Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
	FormControl,
	Select,
	MenuItem,
	TextField,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { LoadingButton } from 'components';
import { Animator } from "lottie-react";
import checkedIcon from "assets/checked_icon.json";

const useStyles = makeStyles(theme => ({
	root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
		overflowX: 'auto',
    padding: 0,
		display: 'flex',
		flexGrow: 1,
  },
  inner: {
    minWidth: 1050,
		display: 'flex',
		flexGrow: 1,
  },
	loading: {
	 display: 'flex',
	 justifyContent: 'center',
	 alignItems: 'center',
   flexGrow: 1,
  },
	end: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
  },
	actions: {
		marginLeft: '0.5rem',
	},
}));

const MaskConfig = props => {
	const { className, config, idmask, onFinished, onReset, ...rest } = props;
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const api = new Api({
    user: user
  });
  
  const [state, setState] = useState({
		wbDatas: null,
		columns: config.columns,
		lines: config.lines,
		loading: true,
    error: null,
		finished: false,
  });
	
	let values = [];
	config.columns.map((column, index) => {
		values[index] = {
			position: index,
			idWBData: column.idWBData,
			defaultValue: column.defaultValue
		};
		return null;
	});
	const [formState, setFormState] = useState({
    isValid: false,
    values: values,
    loading: false,
    finished: false,
  });
  	
	const handleColumnChange = (position, idWBData, defaultValue) => {
		let values = formState.values;
		values[position] = {
			position: position,
			idWBData: idWBData,
			defaultValue: defaultValue,
		};
		setFormState({
			...formState,
			values: values
		});
	};
	
	const handleSave = () => {
		setTimeout(() => {
			api.updateMaskData(idmask, formState.values, () => {
				setState({
					...state,
					loading: false,
					error: null,
					finished: true,
				});
				setFormState({
					...formState,
					loading: false
				});
				onFinished();
			},
			[
				new ApiError({
					idError: 0,
					handle: (error, props) => {
						setState({
							...state,
							wbDatas: null,
							loading: false,
							error: {
								errorMsg: "Une erreur est survenue",
								idError: error.state.idError
							}
						});
						onFinished();
					},
				}),
			]);
		}, 900);
	};
  
	useEffect(() => {
    if((state.error === null) && (state.wbDatas === null))
		{
			api.getWBDatas((wbDatas) => {
				setState({
					...state,
					wbDatas: wbDatas,
					loading: false,
					error: null
				});
			},
			[
				new ApiError({
					idError: 0,
					handle: (error, props) => {
						setState({
							...state,
							wbDatas: null,
							loading: false,
							error: {
								errorMsg: "Impossible de récupérer les données WB, veuillez réessayer",
								idError: error.state.idError,
							}
						});
						onFinished();
					},
				}),
			]);
		}
	});
  
  const tableHead = (
    <TableHead>
      <TableRow>
					<TableCell>Colonne n°</TableCell>
					<TableCell>Valeur</TableCell>
					<TableCell>Donnée WB associée</TableCell>
					<TableCell>Valeur par défaut</TableCell>
      </TableRow>
		</TableHead>
  );
  
  const tableMask = (
    <Table>
	  {tableHead}
		<TableBody>
			{state.columns.map((column, index) => (
				<TableRow
					key={index}
					hover
				>
					<TableCell>{index + 1}</TableCell>
					<TableCell>{state.lines[0][index]}</TableCell>
					<TableCell>
					{state.wbDatas !== null &&
						<FormControl variant="outlined" className={classes.formControl}>
							<Select
								value={formState.values[index].idWBData}
								onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
									handleColumnChange(index, event.target.value, formState.values[index].defaultValue);
								}}
							>
								{state.wbDatas.map(wbData => (
									<MenuItem
										key={wbData.idWBData}
										value={wbData.idWBData}
									>
										{wbData.dataName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					}
					</TableCell>
					<TableCell>
						<TextField
							fullWidth
							variant="outlined"
							type="text"
							value={formState.values[index].defaultValue}
							onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
								handleColumnChange(index, formState.values[index].idWBData, event.target.value);
							}}
						/>
					</TableCell>
				</TableRow>
			))}  
		</TableBody>
    </Table>
  );
  
  const loading = (
    <div className={classes.loading}>
      <CircularProgress size={30} />
    </div>
  );
  
  const error = (
		<div
			className={classes.end}
		>
			<ErrorIcon
				style={{ fontSize: 120 }}
				color='error'
			/>
			<Typography
				className={classes.quoteText}
				variant="h3"
			>
				Oops...
			</Typography>
			{ (state.error !== null) && (state.error.errorMsg !== null) &&
				<Typography
					className={classes.quoteText}
					variant="h6"
					style={{ marginTop: '10px' }}
				>
					{state.error.errorMsg}
				</Typography>
			}
			{ (state.error !== null) && (state.error.idError !== null) &&
				<Typography
					className={classes.quoteText}
					variant="caption"
					style={{
						marginTop: '20px',
						fontStyle: 'italic',
					}}
				>
					Code erreur : {state.error.idError}
				</Typography>
			}
			<Button
				color="primary"
				size="small"
				variant="text"
				style={{ marginTop: '10px' }}
				onClick={() => {
					onReset();
				}}
					
			>
				<ArrowLeftIcon /> Retour
			</Button>
		</div>
  );
  
   const noLines = (
    <div className={classes.error}>
      <Typography
        className={classes.quoteText}
        variant="h6"
        style={{ marginTop: '30px' }}
      >
        Le fichier ne contient aucune ligne.
      </Typography>
    </div>
  );
	
	const finished = (
		<div
			className={classes.end}
		>
			<Animator
				animationData={checkedIcon}
				autoplay={true}
				loop={false}
				style={{
					width: '150px',
					height: '150px',
				}}
			>
			</Animator>
			<Typography
				className={classes.quoteText}
				variant="h5"
			>
				Données enregistrée avec succès.
			</Typography>
			<Typography
				className={classes.quoteText}
				variant="caption"
				style= {{
					marginTop: '20px',
					marginBottom: '20px'
				}}
			>
				Vous pouvez désormais continuer la configuration dans l'interface administrateur.
			</Typography>
		</div>
	);

	return (
		<Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Configuration du masque"
      />
      <Divider />
      <CardContent className={classes.content}>
				<div className={classes.inner}>
					{(state && state.lines && (state.wbDatas !== null)) ? (state.error !== null ? error : (state.finished ? finished : (state.lines.length > 0 ? tableMask : noLines))) : loading}
				</div>
			</CardContent>
      <Divider />
			{ !state.loading && state.error === null && !state.finished && 
				<CardActions className={classes.actions}>
					<LoadingButton
						color="primary"
						variant="outlined"
						type="submit"
						loading={formState.loading}
						done={formState.finished}
						onClick={() => {
							setFormState(formState => ({
								...formState,
								loading: true,
							}));
							handleSave();
						}}
					>
						Enregistrer
					</LoadingButton>
				</CardActions>
			}
    </Card>
  );
};

MaskConfig.propTypes = {
  className: PropTypes.string,
};

export default MaskConfig;
