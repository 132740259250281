import React, { Component } from 'react'

const UserContext = React.createContext()

export const UserConsumer = UserContext.Consumer

class UserProvider extends Component {
  constructor(props) {
	super(props);
	const user = props.value
    this.state = {
      user: user,
    }
  }
  

  setUser = user => {
    this.setState(prevState => ({ user }))
  }

  render() {
    const { children } = this.props
    const { user } = this.state
    const { setUser } = this

    return (
      <UserContext.Provider
        value={{
          user,
          setUser,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext

export { UserProvider }