import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const RGPDDialog = props => {
	const { open, handleClose } = props;
	
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle>
				Politique de confidentialité
			</DialogTitle>
			<DialogContent>
				<div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>ARTICLE 1 : PRÉAMBULE</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Cette politique de confidentialité s'applique au site : avdmsolutions.ch.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :</span></span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '26px'}}>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>La manière dont sont collectées et traitées leurs données à caractère</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>personnel. Doivent être considérées comme données personnelles toutes les</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>données étant susceptibles d'identifier un utilisateur. Il s'agit notamment du</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>prénom et du nom, de l'âge, de l'adresse postale, l'adresse mail, la localisation</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>de l'utilisateur ou encore son adresse IP ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Quels sont les droits des utilisateurs concernant ces données ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Qui est responsable du traitement des données à caractère personnel</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>collectées et traitées ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>A qui ces données sont transmises ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Eventuellement, la politique du site en matière de fichiers "cookies".</span></span></li>
        </ul>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Cette politique de confidentialité complète les mentions légales et les Conditions</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Générales d'Utilisation que les utilisateurs peuvent consulter à l'adresse ci-après :</span></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ul style={{marginBottom: '0cm', listStyleType: 'undefined', marginLeft: '26px'}}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>infos@avdmsolutions.ch</span></span></li>
          </ul>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>TRAITEMENT DE DONNÉES</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des données des utilisateurs du site respectent les principes suivants :</span></span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '26px'}}>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées qu'avec le consentement de l'utilisateur propriétaire des données. A chaque fois que des données à caractère personnel seront collectées, il sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons ses données sont collectées ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs objectifs déterminés dans les présentes conditions générales d'utilisation ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution des objectifs poursuivis par le site sont collectées ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, dont l'utilisateur est informé. Lorsque cette information ne peut pas être communiquée, l'utilisateur est informé des critères utilisés pour déterminer la durée de conservation ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données s'engage à garantir l'intégrité et la confidentialité des données collectées.</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des conditions ci-après énumérées :</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>L'utilisateur a expressément consenti au traitement ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Le traitement est nécessaire à la bonne exécution d'un contrat ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Le traitement répond à une obligation légale ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève de l'exercice de l'autorité publique ;</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes et privés poursuivis par le responsable du traitement ou par un tiers.</span></span></li>
        </ul>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Les données à caractère personnel collectées sur le site avdmsolutions.ch sont les suivantes :</span></span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '26px'}}>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Société</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Prénom¨</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Nom</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Adresse</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>telephone</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>adresse mail</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>journal des ventes utilisateur</span></span></li>
        </ul>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le site :</span></span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '26px'}}>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Souscription du contrat origine</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Utilisation de la plateforme</span></span></li>
        </ul>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Par ailleurs, lors d'un paiement sur le site, il sera conservé dans les systèmes informatiques de l'éditeur du site une preuve de la transaction comprenant le bon de commande et la facture.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables de sécurité l'ensemble des données collectées pour une durée de : durée du contrat, ensuite effacement.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>La collecte et le traitement des données répondent aux finalités suivantes :</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Données minimales utiles a la plateforme uniquement.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Les données de localisation ne sont pas exploitées</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>B. TRANSMISSION DES DONNÉES A DES TIERS</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers, et ne sont traitées que par l'éditeur du site.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>C. HÉBERGEMENT DES DONNÉES</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Le site avdmsolutions.ch est hébergé par : Infomaniak, dont le siège est situé à l'adresse ci-après :</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Suisse</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>L'hébergeur peut être contacté au numéro de téléphone suivant : ________</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Les données collectées et traitées par le site sont transférées vers le(s) pays suivant(s) : Suisse. Ce transfert de données à caractère personnel en dehors de</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>l'Union européenne se justifie par les raisons ci-après :</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Suisse pour sa sécurité</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'upper-alpha', marginLeft: '8px'}} start={1}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt'}}>LE RESPONSABLE DU TRAITEMENT DES DONNÉES</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Le responsable du traitement des données à caractère personnel est : Arnaud Vandemeulebroucke. Il peut être contacté de la manière suivante :</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Par email : contact@avdmsolutions.ch</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Par tel : +41 76 637 79 35 ou +33 6 84 84 18 10</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis au service du traitement des données à caractère personnel.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'upper-alpha', marginLeft: '8px'}} start={2}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt'}}>OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été informéet à respecter les finalités pour lesquelles ces données ont été collectées.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Un certificat SSL ("Secure Socket Layer" Certificate) a pour but de sécuriser les données échangées entre l'utilisateur et le site.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches disproportionnés.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur est compromise, le responsable du traitement s'engage à informer l'utilisateur par tout moyen.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>ARTICLE 5 : DROITS DE L'UTILISATEUR</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède les droits ci-après énumérés.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui communiquer : ses prénom et nom ainsi que son adresse email, et si cela est pertinent, son numéro de compte ou d'espace personnel ou d'abonné.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximum.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'upper-alpha', marginLeft: '8px'}} start={1}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt'}}>PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'lower-alpha', marginLeft: '26px'}} start={1}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt'}}>Droit d'accès, de rectification et droit à l'effacement</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le concernant, en respectant la procédure ci-après énoncée :</span></span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '26px'}}>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>L Utilisateur ouvre un compte lors de la souscription du contrat.</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>L Utilisateur peut contacter a tout moment le responsable des données a l adresse mentionnée ci dessus, afin de modifier ou supprimer des données personnelles</span></span></li>
        </ul>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>S'il en possède un, l'utilisateur a le droit de demander la suppression de son espace personnel en suivant la procédure suivante :</span></span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '26px'}}>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>L utilisateur peut, si il clôture sa souscription contractuelle (aux conditions commerciales énoncées) et sur simple demande supprimer son compte et effacer ses données.</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>L ensemble des ses données seront supprimées automatiquement en cas de rupture du contrat initial dans un délai de 5 jours ouvrables.</span></span></li>
        </ul>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'lower-alpha', marginLeft: '26px'}} start={2}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt'}}>Droit à la portabilité des données</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un autre site, en se conformant à la procédure ci-après :</span></span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '26px'}}>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>L utilisateur a la possibilité des modifier sur simple demande</span></span></li>
          <li><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>L ensemble de ses coordonnées (email, adresse.) en cas de changement important de sa situation.</span></span></li>
        </ul>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'lower-alpha', marginLeft: '26px'}} start={3}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt'}}>Droit à la limitation et à l'opposition du traitement des données</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l'utilisateur.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses données, l'utilisateur doit suivre la procédure suivante :</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>L Utilisateur ne peut pas demander la limite de traitement de ses données car elles sont nécessaires au traitement interne de l application et ne sont pas publiées.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'lower-alpha', marginLeft: '26px'}} start={4}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt'}}>Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé si la décision produit des effets juridiques le concernant, ou l'affecte de manière significative de façon similaire.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'lower-alpha', marginLeft: '26px'}} start={5}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt'}}>Droit de déterminer le sort des données après la mort</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>
          <ol style={{marginBottom: '0cm', listStyleType: 'lower-alpha', marginLeft: '26px'}} start={6}>
            <li style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', fontSize: '11.0pt', color: 'windowtext', fontStyle: 'normal'}}>Droit de saisir l’autorité de contrôle compétente</span></strong></span></li>
          </ol>
        </div>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et que l'utilisateur souhaite contester cette décision, ou, s'il pense qu'il est porté atteinte à l'un des droits énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés, https://www.cnil.fr) ou tout juge compétent.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>CONFIDENTIALITÉ</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après indiquée :</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>infos@avdmsolutions.ch</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin de se tenir informé des derniers changements qui lui seront apportés.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Toutefois, en cas de modification substantielle de cette politique, l'utilisateur en sera informé de la manière suivante :</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginLeft: '36.0pt'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>L utilisateur sera informé par email</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de confidentialité est intervenue le : 07/07/2020.</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>ARTICLE 7 : ACCEPTATION PAR L'UTILISATEUR DE LA POLITIQUE DE</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>CONFIDENTIALITÉ</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><strong><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>&nbsp;</span></strong></span></p>
        <p style={{margin: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Helvetica",sans-serif', color: 'gray', fontWeight: 'normal', fontStyle: 'italic'}}><span style={{fontFamily: '"Calibri",sans-serif', color: 'windowtext', fontStyle: 'normal'}}>En naviguant sur le site, l'utilisateur atteste avoir lu et compris la présente politique de confidentialité et en accepte les conditions, en ce qui concerne plus particulièrement la collecte et le traitement de ses données à caractère personnel.</span></span></p>
      </div>
					
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleClose} color="primary">
					Fermer
				</Button>
			</DialogActions>
		</Dialog>
  );
};
export default RGPDDialog;
